import React, { Component } from "react";
import './faq.css';

export default class FAQ extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentFaq: 1,
        }
    }

    componentWillMount() {
    }

    faqChanged(index) {
        if (this.state.currentFaq == index) {
            this.setState({
                currentFaq: -1
            })
        } else {
            this.setState({
                currentFaq: index
            })
        }
    }

    render() {
        return (
            <div>
                <div className="container" style={{marginBottom:'10px'}}>
                    <div className="col-md-12">
                        <h3 className="title" style={{ textAlign: 'center' }}>FAQs</h3>
                        <div id="accordionExample" className="accordion mt-5">
                            <div className="card">

                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(1) }}>
                                    <h2 className="mb-0 mt-0">
                                        <span className="headerTitle"> What is Total Market Maker Staking Balance? </span>
                                    </h2>
                                </header>
                                <div role="tabpanel" style={{ display: (this.state.currentFaq == 1 ? 'block' : 'none') }} className="collapse">
                                    <div className="card-body ">
                                        <p >This is amount of NRGY currently staked into the contract.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(2) }}>
                                    <h2 className="mb-0 mt-0">
                                        <span className="headerTitle"> What is Total Market Maker Rewards Generated? </span></h2>
                                </header>
                                <div role="tabpanel" style={{ display: (this.state.currentFaq == 2 ? 'block' : 'none') }} className="collapse">
                                    <div className="card-body">
                                        <p >This is total amount of rewards generated till date including earned rewards and fees rewards in the contract for all staking holders.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(3) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Current Week Out of 100? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 3 ? 'block' : 'none') }}>
                                    <div className="card-body fadeInUp">
                                        <p >This will tell the current week which is going on. The total week is 100. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(4) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Total Market Maker Staking Added for Week?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 4 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This is the total amount of NRGY staked in current week.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(5) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">What is Available Market Maker Staking for Week? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 5 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This is the total amount of new NRGY that can be deposited into market maker staking each week. Once the limit is reached, no new coin can be deposited.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(6) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Last reward date?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 6 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This signifies the last earned NRGY rewards distribution date. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(7) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is current week limit is not reached? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 7 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >The limit will be rolled over to the next week. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(8) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Your staked NRGY?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 8 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This is the amount of NRGY you’ve added to the staking contract. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(9) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Total NRGY Earned? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 9 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This is amount of NRGY you’ve earned today. This amount will fluctuate as this week rewards are recalculated real time. If you withdraw any NRGY from the staking contract, you total NRGY will be reduced first before your staking share is affected. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(10) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is NRGY available to claim? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 10 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This is the amount of NRGY you can withdraw. This week’s NRGY rewards which is being calculated real time will not be available to claim until it’s finalized at the start of the next week.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(11) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">What is Rewards earned this week?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 11 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This shows your current week rewards calculated in real time which will be fluctuating. These will be available to claimed at the start of the next week after finalization.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(12) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Your Market Maker Staking Pool Share %?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 12 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This shows your percentage in the pool. Earned NRGY rewards are considered while calculating pool share.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(13) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is Your NRGY Balance? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 13 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p > This is the amount of NRGY who have in your metamask wallet. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(14) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> How can I see the most recent audit report? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 14 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >You can see audit report <a className="link" href="/media/files/NRGY%20Smart%20Contracts%20Audit.pdf" target="_blank">here</a>. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(15) }}>
                                    <h2 className="mb-0 mt-0">
                                        <span className="headerTitle"> What happens to coins earned from staking each week? Do I need to pay to stake those? </span></h2>
                                </header>
                                <div  role="tabpanel" style={{ display: (this.state.currentFaq == 15 ? 'block' : 'none') }} className="collapse">
                                    <div className="card-body">
                                        <p >A snapshot is programmatically taken of your Earned NRGY balance at a different time each month. Based on this, a reward is generated that’s available to be claimed minus a 7.5% burn.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(16) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> How can I find NRGY on Uniswap? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 16 ? 'block' : 'none') }}>
                                    <div className="card-body fadeInUp">
                                        <p >You can visit here,<a className="link" target="_blank" href="https://v2.info.uniswap.org/token/0xc29acac647c63dbd8618e817d41ea9de69174ae1"> https://v2.info.uniswap.org/token/0xc29acac647c63dbd8618e817d41ea9de69174ae1</a> </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(17) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What is NRGY contract address?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 17 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p ><a className="link" target="_blank" href="https://etherscan.io/token/0xc29acac647c63dbd8618e817d41ea9de69174ae1">https://etherscan.io/token/0xc29acac647c63dbd8618e817d41ea9de69174ae1</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(18) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">Explain the 25% builder advance? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 18 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p > Since there is no company behind NRGY the community builder was created to increase awareness and increase market value of NRGY. Community building staker agree to advanced 25% of their staking deposit amount to a two-person builder team. The first Builder receives 15% paid directly their wallet and the second receives 10%. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(19) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> Why do I pay 3 separate transactions to begin staking?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 19 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >The first two transaction is for approving NRGY Market maker Contract and Community builder Contract respectively. By approving, you allow your consent to the contract to spend coin on your behalf. Finally, the last transaction is the stake transaction. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(20) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> Do I have full access to my funds? Elaborate </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 20 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >NRGY platform is designed to be fully decentralized. In other words, there is no central ownership in the contract. Therefore, only you’ve access to your staked NRGY inside the contract which can be unstaked only by you. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(21) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">How do I use MetaMask?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 21 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >MetaMask is a web extension, which allows you to manage your Ethereum private keys via your web browser. By doing so, it serves as a wallet for Ether and ERC20 tokens, and allows you to visit the distributed web of tomorrow in your browser today. Please visit here to setup your metamask,<a className="link" target="_blank" href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask"> https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask</a> </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(22) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> Why do I get an error (insufficient funds)? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 22 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >This error signifies that you don’t have sufficient ether or NRGY tokens in your wallet to process the transaction. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(23) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> How can I check AFTER I connect my wallet that the inviter address IS actually the one I was given? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 23 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >Please make sure that the address in the URL should be same with the one mentioned on the website below <b>Invited by.</b></p>
                                        <img src="./media/img/faq1.png" style={{width:'100%'}}></img>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(24) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">Why does my MetaMask keep disappearing? How do I “pin it” to the front of chrome </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 24 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >There’s puzzle icon in top right of Chrome. Click the icon, it will expand out a list of extensions installed. Then click the pin icon next to MetaMask and it will add it back to the extension’s toolbar.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(25) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> What if I want two separate accounts (wallets) to buy NRGY. Eg. Personal and company. How can I do this? Do I need 2 separate devices?</span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 25 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p >No, you don’t need two separate devices. Instead, you can create any number of accounts in MetaMask. Kindly visit here to create new accounts in MetaMask,<a className="link" target="_blank" href="https://metamask.zendesk.com/hc/en-us/articles/360015289452-How-to-Create-Additional-MetaMask-Accounts"> https://metamask.zendesk.com/hc/en-us/articles/360015289452-How-to-Create-Additional-MetaMask-Accounts</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(26) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> I have some ridiculous high gas fee when I go to stake - what do I do? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 26 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p > The gas fee is higher because of network congestion. You can choose to use a lower gas fees if it’s higher. There’s downside of choosing a lower gas fees, that there may be a probability that it will be pending for a long time. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(27) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle"> Can I choose gas fees? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 27 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                    <p > 01. Confirming an action in the NRGY website brings up the MetaMask window ‘Confirm Transaction’. </p>
                                        <p > 02. Find ‘Gas Fee’. </p>
                                        <p > 03. Click ‘Edit’ next to ‘Gas Fee’ to set a custom Gas Price. </p>
                                        <p > 04. Next, you need to find the average Gas Price at that time on the Ethereum network. </p>
                                        <p > 05. In another tab, visit <a className="link" target="_blank" href="https://ethgasstation.info/">ETH Gas Station.</a> </p>
                                        <p > 06. There you will find the ‘Recommended Gas Prices in Gwei’. </p>
                                        <p > 07. {'If you’re in a hurry, you can try Gas Price Standard/Std (< 5 minutes) or Fast (<2 minutes).'} </p>
                                        <p > 08. Click on the fox logo in your browser to open the MetaMask confirmation window again. </p>
                                        <p > 09. In the Gas Price field, enter the price from ETH Gas Station. </p>
                                        <p >10. Click ‘SAVE’ to save your custom gas fee. </p>
                                        <p >11. Click ‘CONFIRM’ to submit your transaction. </p>
                                        <p >12. That’s it! </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <header role="tab" className="card-header" onClick={() => { this.faqChanged(28) }}>
                                    <h2 className="mb-0 mt-0">
                                    <span className="headerTitle">I keep trying to stake but it won’t go through. How do I cancel the failed pending transactions? </span></h2>
                                </header>
                                <div role="tabpanel" className="collapse" style={{ display: (this.state.currentFaq == 28 ? 'block' : 'none') }}>
                                    <div className="card-body">
                                        <p > Please visit here to know the steps to cancel a pending transaction, <a className="link" target="_blank" href="https://metamask.zendesk.com/hc/en-us/articles/360015489251-How-to-Speed-Up-or-Cancel-a-Pending-Transaction#:~:text=To%20cancel%20the%20transaction%2C%20simply,refer%20to%20Eth%20Gas%20Station">https://metamask.zendesk.com/hc/en-us/articles/360015489251-How-to-Speed-Up-or-Cancel-a-Pending-Transaction#:~:text=To%20cancel%20the%20transaction%2C%20simply,refer%20to%20Eth%20Gas%20Station.</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}