import React, { Component } from "react";
import Web3 from 'web3';
import toastr from 'toastr';
import $, { type } from 'jquery';
import ReactTooltip from 'react-tooltip';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Modal from '../modal';
import 'toastr/build/toastr.min.css';
import {
    LIST_ADDRESS_POLY_NRGY,
    SPENDER_ACCOUNT_ADDRESS,
    LIST_ADDRESS_NRGY_MARKET_MAKER_RINKBY, LIST_ADDRESS_NRGY_RINKBY, LIST_ADDRESS_LIQUIDITY_MARKET_MAKER_RINKBY, LIST_ADDRESS_USDC_RINKBY, LIST_ADDRESS_USDC_NRGY_PAIR_RINKBY, LIST_ADDRESS_NRGY_FUND_RINKBY, LIST_ADDRESS_DOEN_CODE_RINKBY, LIST_ADDRESS_YIELD7_RINKBY, LIST_ADDRESS_YIELD7_NEW_RINKBY, LIST_ADDRESS_REFERRAL_RINKBY, LIST_ADDRESS_YIELD_RINKBY, LIST_ADDRESS_WETH_NEW_RINKBY, LIST_ADDRESS_ROUTE_NEW_RINKBY, LIST_ADDRESS_MIGRATE_RINKBY,
    LIST_ADDRESS_NRGY_MARKET_MAKER_MAIN, LIST_ADDRESS_NRGY_MAIN, LIST_ADDRESS_LIQUIDITY_MARKET_MAKER_MAIN, LIST_ADDRESS_REFERRAL_MAIN, LIST_ADDRESS_USDC_MAIN, LIST_ADDRESS_USDC_NRGY_PAIR_MAIN, LIST_ADDRESS_NRGY_FUND_MAIN, LIST_ADDRESS_YIELD7_MAIN, LIST_ADDRESS_YIELD7_NEW_MAIN, LIST_ADDRESS_WETH_NEW_MAIN, LIST_ADDRESS_ROUTE_NEW_MAIN, LIST_ADDRESS_MIGRATE_MAIN,
    LIST_ADDRESS_DOEN_CODE_MAIN, LIST_ADDRESS_YIELD_MAIN, LIST_ABI_NRGY_MARKET_MAKER, LIST_ABI_NRGY, LIST_ABI_LIQUIDITY_MARKET_MAKER, LIST_ABI_USDC, LIST_ABI_USDC_NRGY_PAIR, LIST_ABI_REFERRAL, LIST_ABI_NRGY_FUND, LIST_ABI_NRGY_DOEN_CODE_CLAIM, LIST_ABI_NRGY_DOEN_CODE_UNSTAKE, LIST_ABI_YIELD, LIST_ABI_YIELD2, LIST_ABI_ROUTER, LIST_ABI_MIGRATE, LIST_RECEIVER_MAIN, MAINNET_HTTP_POLYGON, LIST_ABI_ERC20, LIST_ADDRESS_BRIDGE_ETH_MAIN, LIST_ABI_BRIDGE, MAINNET_HTTP_POLYGON_ANKR, LIST_ABI_POLY_NRGY
} from '../../config';
import { CLAIM } from '../../claim';
import axios from "axios";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { gsap, Back, TimelineMax, Power0 } from "gsap/all";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

let refererDefault = '0x4457099b405E2a9302b2A182630cB06C1b6a5B5C';
let userReferer = '';
let decimal18 = 1000000000000000000;
let decimal6 = 1000000;

export default class Invest extends Component {


    web3_NRGY_MARKET_MAKER = null;
    web3_NRGY = null;
    web3_LIQUIDITY_MARKET_MAKER = null;
    web3_USDC = null;
    web3_USDC_NRGY_PAIR_MAIN = null;
    web3_REFERRAL = null;
    web3_NRGY_FUND = null;
    web3_NRGY_DOEN_CODE_CLAIM = null;
    web3_NRGY_DOEN_CODE_UNSTAKE = null;
    web3_YIELD = null;
    web3_YIELD7 = null;
    web3_NEW_YIELD7 = null;
    web3_ROUTER = null;
    web3_USDC_ETH = null;
    web3_BRIDGE_ETH = null;

    constructor(props) {
        super(props);
        this.state = {
            link: '',
            isActiveUser: false,
            isEnabled: false,
            isConnecting: false,
            popupVisible: false,
            totalShares: '-',
            totalRewards: '-',
            weekCount: '-',
            totalSharesThisWeek: '-',
            remainStakingLimitThisWeek: '-',
            lastRewardTime: '-',
            unstaknrgy: '-',
            staknrgy: '-',
            mmStakingPoolShare: '-',
            rewardsEarnedThisWeek: '-',
            totalNRGYEarned: '-',
            unstaknrgyAvail: '-',
            currentAssetId: 0,
            web3: null,
            account: '',
            usdcBal: "-",
            currentEthBal: '-',
            stakeAmount: '',
            stakeAmountNRGY: '',
            stakeAmountETH: '',
            stakeAmountUSDC: '',
            unstakeAmount: '',
            nrgyBalancePolygon: '-',
            isStakeError: false,
            isEthStakeError: false,
            isUsdcStakeError: false,
            isUnStakeError: false,
            stakeStatus: 'start',
            stakeStatusType: null,
            unStakeStatus: 'start',
            networkType: '',
            LIST_ADDRESS_NRGY_MARKET_MAKER: null,
            LIST_ADDRESS_NRGY: null,
            LIST_ADDRESS_LIQUIDITY_MARKET_MAKER: null,
            LIST_ADDRESS_USDC: null,
            USDC_NRGY_PAIR: null,
            LIST_ADDRESS_REFERRAL: null,
            LIST_ADDRESS_NRGY_FUND: null,
            LIST_ADDRESS_DOEN_CODE: null,
            LIST_ADDRESS_YIELD: null,
            LIST_ADDRESS_YIELD7: null,
            LIST_ADDRESS_YIELD7_NEW: null,
            LIST_ADDRESS_WETH: null,
            LIST_ADDRESS_ROUTER: null,
            LIST_ADDRESS_MIGRATE: null,
            LIST_ADDRESS_BRIDGE_ETH: null,
            isDefaultEnabled: false,
            polygonNRGYMarketMakerInstance: null,
            polyNRGYIInfuraInstance: null,
            referralRewards: '-',
            referralRewardsL1: '-',
            referralRewardsL2: '-',
            price: '-',
            pendingIdList: [],
            hasReferralLink: false,
            isUserPreviouslyStaked: false,
            NMMSRewards: 0,
            startWeek: 0,
            isUnstakeOpen: false,
            isNotLoaded: true,
            claimTimeDiff: 10000000000,
            claimStatus: 'start',
            endWeek: -1,
            rewardsClaimable11: '-',
            rewardsClaimable7: '-',
            activeStep: 'ETH',
            nrgyFromInput: '',

            claimMigrateAmount: '',
            allowanceAmount: 0,
            claimMigrateStatus: 'ready',

            usdcEthBal: '-',
            stakeUSDCEthStatus: 'start',
            depositUSDCEth: '',
            isStakeUSDCEthError: false,
        }

        // this.connectToNetwork = this.connectToNetwork.bind(this);

        let hash = (window.location.hash).split('#/id=');
        // console.log(hash)
        if (hash && hash[1]) {
            userReferer = hash[1];
        }

        toastr.options = {
            // positionClass: 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 3000
        }

    }

    componentWillMount() {
        this.counter();
        this.counterClaim();
        if (userReferer && userReferer != '') {
            this.setState({ hasReferralLink: true })
        }

        setTimeout(() => {
            $('.c-c').css('stroke-dashoffset', -1225);
            $('.t-time').text('0');
            $('.oop').text('sold out of 28,000');

            this.connectToInfuraLinks();
        })

    }

    handleClick(e) {
        e.preventDefault();
    }

    async connectToMetaMaskNetwork() {
        if (this.state.isConnecting) {
            return;
        }
        this.setState({ isConnecting: true, popupVisible: false });
        if (window.ethereum) {
            await window.ethereum.enable().then((err, res) => {
                // console.log("Res")
            });
            this.state.web3 = new Web3(window.ethereum);
            this.props.values.web3 = this.state.web3;
            window.ethereum.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.ethereum.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.ethereum.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            window.ethereum.on("disconnect", chainId => {
                document.location.reload();
            });
            try {
                // console.log("YES:::")
                await this.setConfig();
            } catch (e) {
                // User has denied account access to DApp...
                // console.log("ERROR:::::::::::::::::::::", e)
                await this.setState({ isEnabled: false });
            }

        } else if (window.web3 && window.web3.currentProvider) {
            // console.log("DONE::")
            window.web3.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.web3.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            window.web3.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            window.web3.on("disconnect", chainId => {
                document.location.reload();
            });
            this.state.web3 = new Web3(window.web3.currentProvider);
            try {
                await this.setConfig();
            } catch (e) {
                // User has denied account access to DApp...
                // console.log("ERROR:::::::::::::::::::::")
                await this.setState({ isEnabled: false, isConnecting: false });
            }
        } else {
            await this.setState({ isEnabled: false, isConnecting: false });
        }
    }

    async connectToConnectWallet() {
        if (this.state.isConnecting) {
            return;
        }
        this.setState({ isConnecting: true, popupVisible: false });

        const provider = new WalletConnectProvider({
            // infuraId: "99f87cfb6a2b4aaf9c2445644dcdbe35",
            rpc: {
                1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
            },
            chainId: 1,
            qrcode: true,
            pollingInterval: 15000,
            bridge: 'https://pancakeswap.bridge.walletconnect.org/',
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable().then(async (result) => {
            this.state.web3 = new Web3(provider);
            this.props.values.web3 = this.state.web3;
            provider.on('networkChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            provider.on('chainChanged', chainId => {
                // handle the new network
                // document.location.reload();
                this.setConfig();
            })
            provider.on('accountsChanged', chainId => {
                // handle the new network
                document.location.reload();
            })
            provider.on("disconnect", (code, reason) => {
                // console.log(code, reason);
                document.location.reload();
            });
            try {
                await this.setConfig();
            } catch (e) {
                await this.setState({ isEnabled: false });
            }
        }).catch(error => {
            // console.log(error);
            toastr.error("Unable to connect.");
            this.setState({ isEnabled: false, isConnecting: false });
        });
    }

    async setConfig() {
        // console.log("Network changed")
        const Id = await this.state.web3.eth.net.getNetworkType();
        const chainId = await this.state.web3.eth.getChainId();
        const accounts = await this.state.web3.eth.getAccounts();
        this.state.web3.eth.defaultAccount = accounts[0];
        this.configNetwork(Id, chainId, accounts[0])
    }

    async configNetwork(Id, chainId, account) {
        if (chainId !== 1 && chainId !== 4) {
            toastr.error("Please make sure you're connected to correct network")
            await this.setState({
                isEnabled: false,
                networkType: '',
                isConnecting: false,
                LIST_ADDRESS_NRGY_MARKET_MAKER: null,
                LIST_ADDRESS_NRGY: null,
                LIST_ADDRESS_LIQUIDITY_MARKET_MAKER: null,
                LIST_ADDRESS_USDC: null,
                USDC_NRGY_PAIR: null,
                LIST_ADDRESS_REFERRAL: null,
                LIST_ADDRESS_NRGY_FUND: null,
                LIST_ADDRESS_DOEN_CODE: null,
                LIST_ADDRESS_YIELD: null,
                LIST_ADDRESS_YIELD7: null,
                LIST_ADDRESS_YIELD7_NEW: null,
                LIST_ADDRESS_WETH: null,
                LIST_ADDRESS_ROUTER: null,
                LIST_ADDRESS_MIGRATE: null,
                LIST_ADDRESS_BRIDGE_ETH: null,
            })
            return;
        } else if (chainId === 1 || chainId === 4) {
            let LIST_ADDRESS_NRGY_MARKET_MAKER = null;
            let LIST_ADDRESS_NRGY = null;
            let LIST_ADDRESS_LIQUIDITY_MARKET_MAKER = null;
            let LIST_ADDRESS_USDC = null;
            let USDC_NRGY_PAIR = null;
            let LIST_ADDRESS_REFERRAL = null;
            let LIST_ADDRESS_NRGY_FUND = null;
            let LIST_ADDRESS_DOEN_CODE = null;
            let LIST_ADDRESS_YIELD = null;
            let LIST_ADDRESS_YIELD7 = null;
            let LIST_ADDRESS_YIELD7_NEW = null;
            let LIST_ADDRESS_WETH = null;
            let LIST_ADDRESS_ROUTER = null;
            let LIST_ADDRESS_MIGRATE = null;
            let LIST_ADDRESS_BRIDGE_ETH = null;

            if (chainId === 4) {
                LIST_ADDRESS_NRGY_MARKET_MAKER = LIST_ADDRESS_NRGY_MARKET_MAKER_RINKBY;
                LIST_ADDRESS_NRGY = LIST_ADDRESS_NRGY_RINKBY;
                LIST_ADDRESS_LIQUIDITY_MARKET_MAKER = LIST_ADDRESS_LIQUIDITY_MARKET_MAKER_RINKBY;
                LIST_ADDRESS_USDC = LIST_ADDRESS_USDC_RINKBY;
                USDC_NRGY_PAIR = LIST_ADDRESS_USDC_NRGY_PAIR_RINKBY;
                LIST_ADDRESS_REFERRAL = LIST_ADDRESS_REFERRAL_RINKBY;
                LIST_ADDRESS_NRGY_FUND = LIST_ADDRESS_NRGY_FUND_RINKBY;
                LIST_ADDRESS_DOEN_CODE = LIST_ADDRESS_DOEN_CODE_RINKBY;
                LIST_ADDRESS_YIELD = LIST_ADDRESS_YIELD_RINKBY;
                LIST_ADDRESS_YIELD7 = LIST_ADDRESS_YIELD7_RINKBY;
                LIST_ADDRESS_YIELD7_NEW = LIST_ADDRESS_YIELD7_NEW_RINKBY;
                LIST_ADDRESS_WETH = LIST_ADDRESS_WETH_NEW_RINKBY;
                LIST_ADDRESS_ROUTER = LIST_ADDRESS_ROUTE_NEW_RINKBY;
                LIST_ADDRESS_MIGRATE = LIST_ADDRESS_MIGRATE_RINKBY;
                LIST_ADDRESS_BRIDGE_ETH = null;
            } else if (chainId === 1) {
                LIST_ADDRESS_NRGY_MARKET_MAKER = LIST_ADDRESS_NRGY_MARKET_MAKER_MAIN;
                LIST_ADDRESS_NRGY = LIST_ADDRESS_NRGY_MAIN;
                LIST_ADDRESS_LIQUIDITY_MARKET_MAKER = LIST_ADDRESS_LIQUIDITY_MARKET_MAKER_MAIN;
                LIST_ADDRESS_USDC = LIST_ADDRESS_USDC_MAIN;
                USDC_NRGY_PAIR = LIST_ADDRESS_USDC_NRGY_PAIR_MAIN;
                LIST_ADDRESS_REFERRAL = LIST_ADDRESS_REFERRAL_MAIN;
                LIST_ADDRESS_NRGY_FUND = LIST_ADDRESS_NRGY_FUND_MAIN;
                LIST_ADDRESS_DOEN_CODE = LIST_ADDRESS_DOEN_CODE_MAIN;
                LIST_ADDRESS_YIELD = LIST_ADDRESS_YIELD_MAIN;
                LIST_ADDRESS_YIELD7 = LIST_ADDRESS_YIELD7_MAIN;
                LIST_ADDRESS_YIELD7_NEW = LIST_ADDRESS_YIELD7_NEW_MAIN;
                LIST_ADDRESS_WETH = LIST_ADDRESS_WETH_NEW_MAIN;
                LIST_ADDRESS_ROUTER = LIST_ADDRESS_ROUTE_NEW_MAIN;
                LIST_ADDRESS_MIGRATE = LIST_ADDRESS_MIGRATE_MAIN;
                LIST_ADDRESS_BRIDGE_ETH = LIST_ADDRESS_BRIDGE_ETH_MAIN;
            }

            let link = window.location.origin + "/#/id=" + account;
            await this.setState({
                isEnabled: true,
                networkType: Id,
                chainId: chainId,
                account: account,
                link: link,
                LIST_ADDRESS_NRGY_MARKET_MAKER: LIST_ADDRESS_NRGY_MARKET_MAKER,
                LIST_ADDRESS_NRGY: LIST_ADDRESS_NRGY,
                LIST_ADDRESS_LIQUIDITY_MARKET_MAKER: LIST_ADDRESS_LIQUIDITY_MARKET_MAKER,
                LIST_ADDRESS_USDC: LIST_ADDRESS_USDC,
                USDC_NRGY_PAIR: USDC_NRGY_PAIR,
                LIST_ADDRESS_REFERRAL: LIST_ADDRESS_REFERRAL,
                LIST_ADDRESS_NRGY_FUND: LIST_ADDRESS_NRGY_FUND,
                LIST_ADDRESS_DOEN_CODE: LIST_ADDRESS_DOEN_CODE,
                LIST_ADDRESS_YIELD: LIST_ADDRESS_YIELD,
                LIST_ADDRESS_YIELD7: LIST_ADDRESS_YIELD7,
                LIST_ADDRESS_YIELD7_NEW: LIST_ADDRESS_YIELD7_NEW,
                LIST_ADDRESS_ROUTER: LIST_ADDRESS_ROUTER,
                LIST_ADDRESS_MIGRATE: LIST_ADDRESS_MIGRATE,
                LIST_ADDRESS_BRIDGE_ETH: LIST_ADDRESS_BRIDGE_ETH
            });
            await this.getAccount(this.state.web3);
            await this.setContract(this.state.web3);

        }
    }

    async connectToInfuraLinks() {
        try {
            const web3InfuraPolygon = new Web3(new Web3.providers.HttpProvider(MAINNET_HTTP_POLYGON));
            const polygonNRGYMarketMakerInstance = new web3InfuraPolygon.eth.Contract(LIST_ABI_MIGRATE, this.state.LIST_ADDRESS_MIGRATE);

            const web3InfuraPoly = new Web3(new Web3.providers.HttpProvider(MAINNET_HTTP_POLYGON_ANKR));
            const polyNRGYIInfuraInstance = new web3InfuraPoly.eth.Contract(LIST_ABI_POLY_NRGY, LIST_ADDRESS_POLY_NRGY);
            await this.setState({
                isDefaultEnabled: true,
                polygonNRGYMarketMakerInstance: polygonNRGYMarketMakerInstance,
                polyNRGYIInfuraInstance: polyNRGYIInfuraInstance,
            })

            this.checkSoldNRGY();
        } catch (e) {
            console.error(e)
        }
    }

    async getAccount(web3) {
        const accounts = await web3.eth.getAccounts();
        // console.log("====>", accounts);
        let link = window.location.origin + "/#/id=" + accounts[0];
        this.setState({ account: accounts[0], link: link })
        web3.eth.defaultAccount = this.state.account;
    }

    async setContract(web3) {
        // this.props.values.isConnected = true;
        this.web3_NRGY_MARKET_MAKER = new web3.eth.Contract(LIST_ABI_NRGY_MARKET_MAKER, this.state.LIST_ADDRESS_NRGY_MARKET_MAKER);
        this.web3_NRGY = new web3.eth.Contract(LIST_ABI_NRGY, this.state.LIST_ADDRESS_NRGY);
        this.web3_LIQUIDITY_MARKET_MAKER = new web3.eth.Contract(LIST_ABI_LIQUIDITY_MARKET_MAKER, this.state.LIST_ADDRESS_LIQUIDITY_MARKET_MAKER);
        this.web3_USDC = new web3.eth.Contract(LIST_ABI_USDC, this.state.LIST_ADDRESS_USDC);
        this.web3_USDC_NRGY_PAIR_MAIN = new web3.eth.Contract(LIST_ABI_USDC_NRGY_PAIR, this.state.USDC_NRGY_PAIR);
        this.web3_REFERRAL = new web3.eth.Contract(LIST_ABI_REFERRAL, this.state.LIST_ADDRESS_REFERRAL);
        this.web3_NRGY_FUND = new web3.eth.Contract(LIST_ABI_NRGY_FUND, this.state.LIST_ADDRESS_NRGY_FUND);
        this.web3_NRGY_DOEN_CODE_CLAIM = new web3.eth.Contract(LIST_ABI_NRGY_DOEN_CODE_CLAIM, this.state.LIST_ADDRESS_DOEN_CODE);
        this.web3_NRGY_DOEN_CODE_UNSTAKE = new web3.eth.Contract(LIST_ABI_NRGY_DOEN_CODE_UNSTAKE, this.state.LIST_ADDRESS_DOEN_CODE);
        this.web3_YIELD = new web3.eth.Contract(LIST_ABI_YIELD2, this.state.LIST_ADDRESS_YIELD);
        this.web3_YIELD7 = new web3.eth.Contract(LIST_ABI_YIELD, this.state.LIST_ADDRESS_YIELD7);
        this.web3_NEW_YIELD7 = new web3.eth.Contract(LIST_ABI_YIELD, this.state.LIST_ADDRESS_YIELD7_NEW);
        this.web3_ROUTER = new web3.eth.Contract(LIST_ABI_ROUTER, this.state.LIST_ADDRESS_ROUTER);
        this.web3_USDC_ETH = await new web3.eth.Contract(LIST_ABI_ERC20, this.state.LIST_ADDRESS_USDC);
        this.web3_BRIDGE_ETH = await new web3.eth.Contract(LIST_ABI_BRIDGE, this.state.LIST_ADDRESS_BRIDGE_ETH);
        this.loadUSDCEthData();
        this.loadData();
    }

    async loadData() {

        await this.callsABI();

        setTimeout(() => {
            if (this.state.isEnabled) {
                this.loadData();
            }
        }, 30000)
    }

    getFormattedDate(date) {
        // console.log(date)
        if (!date) {
            return '-'
        }
        let hour = ('0' + date.getUTCHours()).slice(-2);
        let minute = ('0' + date.getUTCMinutes()).slice(-2);
        let day = ('0' + date.getUTCDate()).slice(-2);
        let month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        let year = date.getUTCFullYear();
        return month + '/' + day + '/' + year;
    }

    getFormatedNumberUpto(num, decimalPoints) {
        if (num == 0) {
            return 0
        }

        return this.getDecimals((num).toFixed(decimalPoints));
    }

    getMMStakingPoolShare(ammount) {
        if (ammount > 0) {
            return `${ammount}%`;
        }
        return 0;
    }

    getFormatedNumber(num) {
        if (num == 0) {
            return 0
        }

        return this.getDecimals((num / 1000000).toFixed(6));
    }


    getFormatedNumberCustom(num, divBy) {
        if (num == 0) {
            return 0;
        }

        return this.getDecimals((num / divBy).toFixed(6))
    }

    getDecimals(str) {
        if (str.endsWith('.000000')) {
            return Number(str.replace('.000000', ''));
        } else if (str.endsWith('.00000')) {
            return Number(str.replace('.00000', ''));
        } else if (str.endsWith('.0000')) {
            return Number(str.replace('.0000', ''));
        } else if (str.endsWith('.000')) {
            return Number(str.replace('.000', ''));
        } else if (str.endsWith('.00')) {
            return Number(str.replace('.00', ''));
        } else if (str.endsWith('.0')) {
            return Number(str.replace('.0', ''));
        } else {
            return Number(str)
        }
    }

    fixingNumber(num) {
        return this.getDecimals(num.toFixed(6));
    }

    async loadUSDCEthData() {

        await this.callsUSDCEthABI();

        setTimeout(() => {
            if (this.state.isEnabled) {
                this.loadUSDCEthData();
            }
        }, 30000)
    }

    async callsUSDCEthABI() {
        try {
            let balanceOf = await this.web3_USDC_ETH.methods.balanceOf(this.state.account).call();
            this.setState({ usdcEthBal: this.getFormatedNumberCustom(Number(balanceOf), decimal6) });
        } catch (e) {

        }
    }

    async callsABI() {
        try {

            this.getPairReserves();

            this.getUsers();

            this.getPolygonBalance();

            this.getEthBalance();

            this.getTotalRewards();

            this.getPendingId();

            let weekCount = await this.web3_NRGY.methods.weekCount().call();
            // console.log("WeekCount==>" + weekCount)
            this.setState({ weekCount: weekCount })

            this.getTotalShareThisWeek(weekCount);

            this.getRemainStakingLimitThisWeek(weekCount);

            if (weekCount < 2) {
                this.setState({ lastRewardTime: '-' })
            } else {
                this.getLastRewardTime();
            }

            this.getUSDCBalance();

            await this.getTotalShares();

            this.getUnstakNRGY();

            let isUserPreviouslyStaked = await this.web3_NRGY_MARKET_MAKER.methods.isUserPreviouslyStaked(this.state.account).call();
            // console.log("here", isUserPreviouslyStaked)
            this.setState({ isUserPreviouslyStaked: isUserPreviouslyStaked })
            if (isUserPreviouslyStaked) {

                let staknrgy = await this.web3_NRGY_MARKET_MAKER.methods.getUserShare(this.state.account).call();
                await this.setState({ staknrgy: this.getFormatedNumber(staknrgy) })

                if (this.state.totalShares != '-' || this.state.totalShares == '0') {
                    let mmStakingPoolShare = (this.getFormatedNumber(staknrgy) / Number(this.state.totalShares)) * 100;
                    this.setState({ mmStakingPoolShare: this.getMMStakingPoolShare(this.getFormatedNumberUpto(mmStakingPoolShare, 6)) })
                }

                let endWeek = await this.web3_NRGY_MARKET_MAKER.methods.getUserEndedWeek(this.state.account).call();
                let data = await this.getStartWeek2(Number(endWeek), Number(weekCount));
                // console.log("data", data)
                let NMMSRewardsToShow = Number(data.NMMSRewardsToShow);
                let NMMSRewards = Number(data.NMMSRewards);
                let userRewardsByWeekNo = Number(data.userRewardsByWeekNo);
                let userCurrentRewards = Number(data.userCurrentRewards);
                let rewardsEarnedThisWeek = Number(data.rewardsEarnedThisWeek);

                let weekShare7 = await this.web3_NRGY_MARKET_MAKER.methods.getUserShareByWeekNo(this.state.account, "7").call();
                let weekShare11 = await this.web3_NRGY_MARKET_MAKER.methods.getUserShareByWeekNo(this.state.account, "11").call();

                this.setState({ NMMSRewards: NMMSRewards, endWeek: endWeek, weekShare11: weekShare11, weekShare7: weekShare7 });

                let userUnclaimedFeesRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserUnclaimedFeesRewards(this.state.account).call();
                let userTotalRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserTotalRewards(this.state.account).call();

                let unstaknrgyAvail = (Number(userUnclaimedFeesRewards) + Number(userRewardsByWeekNo) + Number(userTotalRewards) + Number(staknrgy) + Number(NMMSRewards));
                this.setState({ unstaknrgyAvail: this.getFormatedNumber(unstaknrgyAvail) });

                this.setState({ rewardsEarnedThisWeek: this.getFormatedNumber(rewardsEarnedThisWeek) })

                if (Number(this.getFormatedNumber(staknrgy)) > 0) {
                    //New Logic
                    let total = this.getFormatedNumber(Number(userTotalRewards) + Number(userRewardsByWeekNo) + Number(userUnclaimedFeesRewards) + (Number(NMMSRewards)));

                    await this.setState({ totalNRGYEarned: total });
                } else {
                    await this.setState({ totalNRGYEarned: 0 });
                }

                this.getTotalRewardsClaimable7();
            } else {
                // console.log("OK")
                let userInfo = await this.web3_NRGY_FUND.methods.userInfo(this.state.account).call();
                // console.log("data" + userInfo)

                let userUnclaimedFeesRewards = await this.web3_NRGY_FUND.methods.getUserUnclaimedFeesRewards(this.state.account).call();
                let userRewardsByWeekNo = await this.web3_NRGY_FUND.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();
                let userRewardsByWeekNo1 = await this.web3_NRGY_FUND.methods.getUserRewardsByWeekNo(this.state.account, Number(weekCount) + 1).call();
                let staknrgy = this.getFormatedNumber(Number(userInfo['share']))
                let totalNRGYEarned = 0;
                if (Number(staknrgy) > 0) {
                    totalNRGYEarned = this.getFormatedNumber(Number(userUnclaimedFeesRewards)) + this.getFormatedNumber(Number(userRewardsByWeekNo)) + this.getFormatedNumber(Number(userInfo['rewards'])) + this.getFormatedNumber(Number(userInfo['feeRewards']));
                }
                // console.log("user", userRewardsByWeekNo, userRewardsByWeekNo1, weekCount);
                let unstaknrgyAvail = this.getFormatedNumber(Number(userUnclaimedFeesRewards)) + this.getFormatedNumber(Number(userRewardsByWeekNo)) + this.getFormatedNumber(Number(userInfo['rewards'])) + this.getFormatedNumber(Number(userInfo['feeRewards'])) + this.getFormatedNumber(Number(userInfo['share']));
                // console.log(userRewardsByWeekNo1, userRewardsByWeekNo1 , Number(userRewardsByWeekNo1) - Number(userRewardsByWeekNo))
                let rewardsEarnedThisWeek = this.getFormatedNumber(Number(userRewardsByWeekNo1) - Number(userRewardsByWeekNo));
                // console.log("totalShares:::" + this.state.totalShares);
                // console.log("Number(userInfo['share'])::" + Number(userInfo['share']))
                let mmStakingPoolShare = (Number(unstaknrgyAvail) * 100 / Number(this.state.totalShares)).toFixed(6);
                // console.log("mmStakingPoolShare::" + mmStakingPoolShare)

                let endWeek = Number(userInfo['endedWeek']);
                let weekShare7 = await this.web3_NRGY_MARKET_MAKER.methods.getUserShareByWeekNo(this.state.account, "7").call();
                let weekShare11 = await this.web3_NRGY_FUND.methods.getUserShareByWeekNo(this.state.account, "11").call();

                await this.setState({
                    totalNRGYEarned: totalNRGYEarned,
                    unstaknrgyAvail: unstaknrgyAvail,
                    staknrgy: staknrgy,
                    rewardsEarnedThisWeek: rewardsEarnedThisWeek,
                    mmStakingPoolShare: mmStakingPoolShare,
                    endWeek: endWeek,
                    weekShare11: weekShare11,
                    weekShare7: weekShare7
                });

                this.getTotalRewardsClaimable7();
            }

            this.getTotalRewardsClaimable11();
            // console.log("YES:::")
            this.setState({ isNotLoaded: false })

        } catch (e) {
            console.error(e)
        }
    }

    async getPolygonBalance() {
        try {
            if (this.state.account && this.state.isDefaultEnabled) {
                let balance = await this.state.polygonNRGYMarketMakerInstance.methods.userInfo(this.state.account).call();
                balance = Number(balance) * 2;
                this.setState({ nrgyBalancePolygon: this.getFormatedNumber(Number(balance)) })
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getStartWeek(endWeek, weekCount) {
        // console.log("ende", endWeek)
        let nmssStartWeek = null;
        if (endWeek <= weekCount) {
            let userNMMSEndWeek = null;
            for (let i = endWeek; i <= weekCount; i++) {
                let userPercentageShareByWeekNo = null;
                let userNextWeekPercentageShare = null;
                let userWeekShare = await this.web3_NRGY_MARKET_MAKER.methods
                    .getUserShareByWeekNo(this.state.account, i).call()
                if (i > 0 && userWeekShare > 0 && endWeek != i) {
                    // console.log("hereeeeeeeeeeeeee", i)
                    userPercentageShareByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods
                        .getUserPercentageShareByWeekNo(this.state.account, i).call();
                    // console.log("userWeekShare", userWeekShare, userPercentageShareByWeekNo)

                    userNextWeekPercentageShare = await this.web3_NRGY_MARKET_MAKER.methods
                        .getUserPercentageShareByWeekNo(this.state.account, i + 1).call();
                    // console.log("userNextWeekPercentageShare", userNextWeekPercentageShare, i + 1)

                    // userNMMSRewards = userNMMSRewards + Number(await this.web3_NRGY_FUND.methods
                    //                             .getNMMSRewardsPerWeek(this.state.account, endWeek).call());

                    // console.log("=-=-=-=-=-", userWeekShare, userPercentageShareByWeekNo, i)
                    //this check endWeek < endCompareWeek - 1 is for
                    //when user has withdrawn 
                    //but week less than end week share is 0

                    // console.log("user............", userPercentageShareByWeekNo, i)

                    if (userPercentageShareByWeekNo == 0 && typeof nmssStartWeek != "number") {
                        // console.log("typeof", typeof nmssStartWeek, typeof 0)
                        nmssStartWeek = i;
                    }

                    if ((userPercentageShareByWeekNo) == 0 && Number(userNextWeekPercentageShare) > 0) {
                        //from i+ 1 week nrgy market mnaker stake & till i week nrgy fund claim
                        userNMMSEndWeek = i + 1
                        // console.log("if start week", userNMMSEndWeek)
                        return {
                            startWeek: userNMMSEndWeek,
                            weekPer: true,
                            nmssStartWeek: nmssStartWeek
                        };
                    } else if ((userPercentageShareByWeekNo) == 0 && Number(userNextWeekPercentageShare) == 0 && weekCount == i) {
                        userNMMSEndWeek = i
                        // console.log("else start wekkk", userNMMSEndWeek)
                        return {
                            startWeek: userNMMSEndWeek,
                            weekPer: false,
                            nmssStartWeek: nmssStartWeek
                        };
                    }
                }
            }
        }
    }

    async getStartWeek2(endWeek, weekCount) {
        // console.log("endWeek", endWeek)
        let setStartWeek = true;
        let nmssStartWeek = null;
        let userNMMSRewards = 0;
        let userCurrentRewards = 0;
        let NMMSRewardsToShow = 0;
        let userRewardsByWeekNo = 0;
        let rewardsEarnedThisWeek = 0;
        let bothWorld = false;

        let userWeekShare = await this.web3_NRGY_MARKET_MAKER.methods
            .getUserShareByWeekNo(this.state.account, endWeek).call()
        let userUnclaimedRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserRewards(this.state.account).call()

        if (userWeekShare >= Number(8000000)) {
            userRewardsByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();
            userCurrentRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserCurrentRewards(this.state.account).call();
            rewardsEarnedThisWeek = Number(userCurrentRewards) - Number(userRewardsByWeekNo) - Number(userUnclaimedRewards);;
            return {
                userCurrentRewards: userCurrentRewards,
                userRewardsByWeekNo: userRewardsByWeekNo,
                NMMSRewardsToShow: NMMSRewardsToShow,
                NMMSRewards: userNMMSRewards,
                rewardsEarnedThisWeek: rewardsEarnedThisWeek
            };
        }
        if (endWeek <= weekCount) {
            for (let i = endWeek; i <= weekCount; i++) {
                let userNMMSEndWeek = null;
                let userPercentageShareByWeekNo = null;
                let userNextWeekPercentageShare = null;
                let userWeekShare = await this.web3_NRGY_MARKET_MAKER.methods
                    .getUserShareByWeekNo(this.state.account, i).call()

                userPercentageShareByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods
                    .getUserPercentageShareByWeekNo(this.state.account, i).call();
                // console.log("userWeekShare, userPercentageShareByWeekNo", userWeekShare, userPercentageShareByWeekNo)
                if (i > 0 && Number(userWeekShare) > 0 && endWeek != i && Number(userPercentageShareByWeekNo) == 0) {
                    userNextWeekPercentageShare = await this.web3_NRGY_MARKET_MAKER.methods
                        .getUserPercentageShareByWeekNo(this.state.account, i + 1).call();
                    // console.log("userNextWeekPercentageShare", userNextWeekPercentageShare, i + 1)

                    //this check endWeek < endCompareWeek - 1 is for
                    //when user has withdrawn 
                    //but week less than end week share is 0

                    if (userPercentageShareByWeekNo == 0 && setStartWeek == true) {
                        nmssStartWeek = i;
                        setStartWeek = false;
                    }
                    // console.log("userNMMSRewardsuserNMMSRewards setStartWeek", userNMMSRewards, nmssStartWeek)

                    if ((userPercentageShareByWeekNo) == 0 && Number(userNextWeekPercentageShare) > 0) {
                        //from nmmstartweek to nmms end week
                        userNMMSEndWeek = i + 1
                        // console.log("if start week", userNMMSEndWeek)
                        setStartWeek = true;
                        // console.log("value----------", i, nmssStartWeek, userNMMSEndWeek)
                        let partialNMMSRewards = await this.web3_NRGY_FUND.methods
                            .getNMMSRewards(this.state.account,
                                userNMMSEndWeek,
                                nmssStartWeek)
                            .call();
                        // console.log("partialNMMSRewards", i, partialNMMSRewards)
                        userNMMSRewards = Number(userNMMSRewards) + Number(partialNMMSRewards);

                        // userNMMSRewards = userNMMSRewards + Number(await this.web3_NRGY_FUND.methods
                        //             .getNMMSRewardsPerWeek(this.state.account, endWeek).call());
                    } else if ((userPercentageShareByWeekNo) == 0 && weekCount == i) {
                        bothWorld = true;
                        userNMMSEndWeek = i;
                        // console.log("if start week", userNMMSEndWeek)
                        setStartWeek = true;
                        // console.log("value----------", i, nmssStartWeek, userNMMSEndWeek)
                        let partialNMMSRewards = await this.web3_NRGY_FUND.methods
                            .getNMMSRewards(this.state.account,
                                userNMMSEndWeek,
                                nmssStartWeek)
                            .call();
                        if (Number(userNextWeekPercentageShare) > 0) {
                            userCurrentRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserCurrentRewards(this.state.account).call();
                            userRewardsByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();
                            rewardsEarnedThisWeek = Number(userCurrentRewards) - Number(userRewardsByWeekNo) - Number(userUnclaimedRewards);
                        } else {
                            NMMSRewardsToShow = await this.web3_NRGY_FUND.methods.getNMMSRewards(this.state.account, Number(i) + 1, i).call()
                            userRewardsByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();
                            rewardsEarnedThisWeek = NMMSRewardsToShow;
                        }
                        // console.log("partialNMMSRewards", i, partialNMMSRewards)
                        userNMMSRewards = Number(userNMMSRewards) + Number(partialNMMSRewards);
                    }
                }
                if (i == weekCount) {
                    if (bothWorld == false) {
                        userRewardsByWeekNo = await this.web3_NRGY_MARKET_MAKER.methods.getUserRewardsByWeekNo(this.state.account, weekCount).call();
                        userCurrentRewards = await this.web3_NRGY_MARKET_MAKER.methods.getUserCurrentRewards(this.state.account).call();
                        rewardsEarnedThisWeek = Number(userCurrentRewards) - Number(userRewardsByWeekNo) - Number(userUnclaimedRewards);
                    }
                    return {
                        userCurrentRewards: userCurrentRewards,
                        userRewardsByWeekNo: userRewardsByWeekNo,
                        NMMSRewardsToShow: NMMSRewardsToShow,
                        NMMSRewards: userNMMSRewards,
                        rewardsEarnedThisWeek: rewardsEarnedThisWeek
                    };
                }
            }
        }
    }

    async getPairReserves() {
        let pairReserves = await this.web3_LIQUIDITY_MARKET_MAKER.methods.getPairReserves(this.state.USDC_NRGY_PAIR, this.state.LIST_ADDRESS_NRGY).call();
        if (pairReserves && pairReserves['usdcReserves'] && pairReserves['nrgyReserves'] && Number(pairReserves['usdcReserves']) > 0 && Number(pairReserves['nrgyReserves']) > 0) {
            let price = Number(pairReserves['usdcReserves']) / Number(pairReserves['nrgyReserves']);
            let totalSupply = await this.web3_NRGY.methods.totalSupply().call();
            let balanceOf = await this.web3_NRGY.methods.balanceOf(this.state.LIST_ADDRESS_LIQUIDITY_MARKET_MAKER).call();
            let circulatingSupply = this.getFormatedNumber(Number(totalSupply) - Number(balanceOf));
            let marketCap = Number(circulatingSupply) * Number(price)
            let elmnt = document.getElementById("blink_me");
            $('.blink_me').html("<span style='margin-right:50px'>1 NRGY = $ " + price.toFixed(2) + " USDC </span>" + this.getLineBreak(elmnt) + "<span style='margin-right:50px'>Circulating Supply : " + this.numberWithCommas(circulatingSupply) + " </span>" + this.getLineBreak(elmnt) + "<span> Market Cap : $" + this.numberWithCommas(this.getFormatedNumberUpto(marketCap, 2)) + "</span>")
            this.setState({ price: price.toFixed(2) })
        }
    }

    getLineBreak(elmnt) {
        if (elmnt && elmnt.offsetWidth <= 880) {
            return '<br/>'
        } else {
            return ''
        }
    }

    async getUsers() {
        try {
            let users = await this.web3_REFERRAL.methods.users(this.state.account).call();
            let isActiveUser = users['isActive'];

            if (users && users['referrer'] && users['referrer'] != "0x0000000000000000000000000000000000000000") {
                userReferer = users['referrer'];
                this.setState({ hasReferralLink: true })
            } else {
                let userReferrerAddress = await this.web3_BRIDGE_ETH.methods.referrerList(this.state.account, 0).call();
                if (userReferrerAddress && userReferrerAddress != "0x0000000000000000000000000000000000000000") {
                    isActiveUser = true;
                    userReferer = userReferrerAddress;
                    this.setState({ hasReferralLink: true })
                }
            }
            if (!userReferer || userReferer == '') {
                isActiveUser = false;
                userReferer = refererDefault;
            }
            this.setState({ isActiveUser: isActiveUser, referralRewards: this.getFormatedNumber(users['refRewards']), referralRewardsL1: users['level1'], referralRewardsL2: users['level2'] })
        } catch (e) {
            // console.log("ERROR::::",e)
            this.setState({ isActiveUser: false, referralRewards: 0, referralRewardsL1: 0, referralRewardsL2: 0 })
        }
    }

    async getEthBalance() {
        let currentEthBal = await this.state.web3.eth.getBalance(this.state.account);
        this.setState({ currentEthBal: this.getFormatedNumber(Number(currentEthBal) / 1000000000000) })
    }

    async getTotalShares() {
        let totalShares = await this.web3_NRGY_MARKET_MAKER.methods.totalShares().call();
        await this.setState({ totalShares: this.getFormatedNumber(totalShares) })
    }

    async getTotalRewards() {
        let totalRewards = await this.web3_NRGY_MARKET_MAKER.methods.totalRewards().call();
        let totalFeeRewards = await this.web3_NRGY_MARKET_MAKER.methods.totalFeeRewards().call();
        this.setState({ totalRewards: Number(this.getFormatedNumber(totalRewards)) + Number(this.getFormatedNumber(totalFeeRewards)) })
    }

    async getTotalShareThisWeek(weekCount) {
        let totalSharesThisWeek = await this.web3_NRGY_MARKET_MAKER.methods.getStakeByWeekNo(weekCount).call();
        this.setState({ totalSharesThisWeek: this.getFormatedNumber(totalSharesThisWeek) })
    }

    async getRemainStakingLimitThisWeek(weekCount) {
        let remainStakingLimitThisWeek = await this.web3_NRGY_MARKET_MAKER.methods.remainingStakingLimit(weekCount).call();
        this.setState({ remainStakingLimitThisWeek: this.getFormatedNumber(remainStakingLimitThisWeek) })
    }

    async getLastRewardTime() {
        let lastRewardTime = await this.web3_NRGY.methods.getLastWeekUpdateTime().call();
        this.setState({ lastRewardTime: this.getFormattedDate(lastRewardTime ? new Date(lastRewardTime * 1000) : null) })
    }

    async getUnstakNRGY() {
        let unstaknrgy = await this.web3_NRGY.methods.balanceOf(this.state.account).call();
        this.setState({ unstaknrgy: this.getFormatedNumber(unstaknrgy) })
    }

    async getUSDCBalance() {
        let usdcBal = await this.web3_USDC.methods.balanceOf(this.state.account).call();
        this.setState({ usdcBal: this.getFormatedNumber(usdcBal) })
    }

    async getTotalRewardsClaimable11() {
        let userInfo = await this.web3_YIELD.methods.userInfo(this.state.account).call();

        if (Number(this.state.endWeek) != 0) {
            this.setState({ rewardsClaimable11: 0 });
        } else if (userInfo['isClaimed'] == true) {
            this.setState({ rewardsClaimable11: 0 });
        } else if (Number(this.state.endWeek) == 0) {
            let totalRewards = await this.web3_YIELD.methods.totalRewards().call();
            let yieldRewards = await this.web3_YIELD.methods.yieldRewards().call();
            // totalRewards = this.getFormatedNumber(totalRewards);
            // yieldRewards = this.getFormatedNumber(yieldRewards);
            let account = this.state.account.toString().toLowerCase();

            if (Number(CLAIM[account]) && Number(CLAIM[account]) > 0) {
                let percentShare = (Number(CLAIM[account]) / 1000000) / Number(totalRewards);
                // console.log(yieldRewards+"==>"+totalRewards+"==>"+percentShare)
                let rewardsClaimable11 = Number(yieldRewards) * percentShare;
                this.setState({ rewardsClaimable11: this.getFormatedNumberUpto(rewardsClaimable11, 6) });
            } else {
                this.setState({ rewardsClaimable11: 0 });
            }
        } else {
            this.setState({ rewardsClaimable11: 0 });
        }
    }

    async getTotalRewardsClaimable7() {
        let userInfo = await this.web3_YIELD7.methods.userInfo(this.state.account).call();
        let userInfoNew = await this.web3_NEW_YIELD7.methods.userInfo(this.state.account).call();

        if (Number(this.state.endWeek) != 0) {
            this.setState({ rewardsClaimable7: 0 });
        } else if (userInfo['isClaimed'] == true || userInfoNew['isClaimed'] == true) {
            this.setState({ rewardsClaimable7: 0 });
        } else {
            let totalRewards = await this.web3_YIELD7.methods.totalRewards().call();
            let yieldRewards = await this.web3_YIELD7.methods.yieldRewards().call();
            // totalRewards = this.getFormatedNumber(totalRewards);
            // yieldRewards = this.getFormatedNumber(yieldRewards);

            let percentShare = Number(this.state.totalNRGYEarned) / Number(totalRewards);
            let rewardsClaimable7 = Number(yieldRewards) * percentShare;
            this.setState({ rewardsClaimable7: this.getFormatedNumberUpto(rewardsClaimable7, 6) });
        }
    }

    async getPendingId() {
        let pendingIds = await this.web3_REFERRAL.methods.pendingId().call();
        // console.log(pendingIds);
        let list = [];
        //pass the last index number
        this.pushRecords(65, pendingIds, list)
    }

    async pushRecords(index, total, list) {
        if (total > index) {
            let data = await this.web3_REFERRAL.methods.pendingList(index).call();
            if (data && data.user == this.state.account) {
                list.push(this.getFormatedNumber(data.amount));
            }
            this.pushRecords(index + 1, total, list)
        } else {
            this.setState({ pendingIdList: list.reverse() })
        }
    }

    getTableContent() {
        if (this.state.pendingIdList.length <= 0) {
            return <h4>No Current Reservations</h4>;
        }
        return this.state.pendingIdList.map((e, key) => {
            return (<div className="col-md-2 pendingIds">
                <span>{e}</span>
            </div>)
            // return <option key={key} value={e.tokenId}>{e.tokenName} ({e.tokenId})</option>;
        })
    }

    async stakeAmountChanged(event, type) {
        if (this.state.stakeStatus == "inprogress") {
            return;
        }
        var num = new Number(event.target.value);
        let number = Number(event.target.value);
        // console.log(num)
        if (!isNaN(num) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            let stateObj = {
                stakeAmount: event.target.value + ''
            }

            stateObj['stakeAmountNRGY'] = '';
            stateObj['stakeAmountUSDC'] = '';
            stateObj['stakeAmountETH'] = '';

            if (type == 'NRGY') {
                stateObj['stakeAmountNRGY'] = event.target.value + '';
            } else if (type == "USDC") {
                let addresses = [this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY];
                this.getNrgy(Number(event.target.value), addresses, "mwei");
                stateObj['stakeAmountUSDC'] = event.target.value + '';
            } else if (type == "ETH") {
                let addresses = [this.state.LIST_ADDRESS_WETH, this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY];
                this.getNrgy(Number(event.target.value), addresses, "ether")
                stateObj['stakeAmountETH'] = event.target.value + '';
            }

            this.setState(stateObj);
        }

        if (type == 'NRGY') {
            if (this.state.unstaknrgy < num) {
                this.setState({
                    isStakeError: true
                })
            } else if (this.state.unstaknrgy >= num) {
                this.setState({
                    isStakeError: false
                })
            }
        } else if (type == "USDC") {
            if (this.state.usdcBal < num) {
                this.setState({
                    isUsdcStakeError: true
                })
            } else if (this.state.usdcBal >= num) {
                this.setState({
                    isUsdcStakeError: false
                })
            }
        } else if (type == "ETH") {
            if (this.state.currentEthBal < num) {
                this.setState({
                    isEthStakeError: true
                })
            } else if (this.state.currentEthBal >= num) {
                this.setState({
                    isEthStakeError: false
                })
            }
        }
    }

    async getNrgy(num, addresses, type) {
        if (num > 0 && this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei((num).toString(), type);
            let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
            let nrgyFromInput = this.state.web3.utils.fromWei((amountOutMin[amountOutMin.length - 1]).toString(), "mwei");
            this.setState({ nrgyFromInput: nrgyFromInput })
        }
    }

    async stakeClick(type) {
        return;
        if (this.state.isEnabled) {
            if (!this.state.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.stakeAmount || Number(this.state.stakeAmount) <= 0) {
                toastr.error("Plase enter valid amount.");
                return;
            }

            if (type == "ETH") {
                if (Number(this.state.stakeAmount) > Number(this.state.currentEthBal)) {
                    toastr.error("Your available balance is " + this.state.currentEthBal + ". Please enter amount accordingly");
                    return;
                }
                if (Number(this.state.stakeAmount) > Number(1)) {
                    toastr.error("Max 1 ether is allowed in single transaction");
                    return;
                }
            } else if (type == "USDC") {
                if (Number(this.state.stakeAmount) > Number(this.state.usdcBal)) {
                    toastr.error("Your available balance is " + this.state.usdcBal + ". Please enter amount accordingly");
                    return;
                }
                if (Number(this.state.stakeAmount) > Number(2500)) {
                    toastr.error("Max 2500 USDC is allowed in single transaction");
                    return;
                }
            } else if (type == "NRGY") {
                if (Number(this.state.stakeAmount) > Number(this.state.unstaknrgy)) {
                    toastr.error("Your available balance is " + this.state.unstaknrgy + ". Please enter amount accordingly");
                    return;
                }
            }

            this.setState({ stakeStatus: 'inprogress', stakeStatusType: type });
            if (type == "ETH") {
                this.stackCall(type);
            } else {
                this.allownce2(type);
            }
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async approveStack1(type) {
        if (this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            await this.web3_NRGY.methods.approve(refererDefault, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                this.allownce2(type);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        }
    }

    async allownce2(type) {
        this.setState({ stakeStatus: 'inprogress' });
        let allowance = await this.web3_NRGY.methods.allowance(this.state.account, this.state.LIST_ADDRESS_NRGY_MARKET_MAKER).call();
        if (Number(allowance) >= Number(this.state.stakeAmount)) {
            if (type == 'NRGY') {
                this.allownce3NRGY(type);
            } else if (type == 'USDC') {
                this.allownce3USDC(type);
            } else {
                this.stackCall(type);
            }
        } else {
            this.approveStack2(type);
        }
    }

    async approveStack2(type) {
        if (this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            await this.web3_NRGY.methods.approve(this.state.LIST_ADDRESS_NRGY_MARKET_MAKER, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                if (type == 'NRGY') {
                    this.allownce3NRGY(type);
                } else if (type == 'USDC') {
                    this.allownce3USDC(type);
                } else {
                    this.stackCall(type);
                }
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        }
    }

    async allownce3NRGY(type) {
        this.setState({ stakeStatus: 'inprogress' });
        let allowance = await this.web3_NRGY.methods.allowance(this.state.account, this.state.LIST_ADDRESS_REFERRAL).call();
        if (Number(allowance) >= Number(this.state.stakeAmount)) {
            this.stackCall(type);
        } else {
            this.approveStack3NRGY(type);
        }
    }

    async approveStack3NRGY(type) {
        if (this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            await this.web3_NRGY.methods.approve(this.state.LIST_ADDRESS_REFERRAL, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                this.stackCall(type);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        }
    }

    async allownce3USDC(type) {
        this.setState({ stakeStatus: 'inprogress' });
        let allowance = await this.web3_USDC.methods.allowance(this.state.account, this.state.LIST_ADDRESS_REFERRAL).call();
        if (Number(allowance) >= Number(this.state.stakeAmount)) {
            this.stackCall(type);
        } else {
            this.approveStack3USDC(type);
        }
    }

    async approveStack3USDC(type) {
        if (this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            await this.web3_USDC.methods.approve(this.state.LIST_ADDRESS_REFERRAL, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                this.stackCall(type);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        }
    }

    async stackCall(type) {
        if (type == "ETH") {
            this.setState({ stakeStatus: 'inprogress' });
            let stakeAmount = this.state.web3.utils.toWei((this.state.stakeAmount).toString(), "ether");
            let account = this.state.account;
            await this.web3_REFERRAL.methods.investEther(userReferer, !this.state.isUserPreviouslyStaked, true).send({
                from: account,
                value: stakeAmount,
                // gasLimit: this.state.web3.utils.toHex(630000),
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ stakeAmount: '' })
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                this.callsABI();
                toastr.success("Staking successful");
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        } else if (type == "NRGY") {
            this.setState({ stakeStatus: 'inprogress' });
            let stakeAmount = this.state.web3.utils.toWei(Number(this.state.stakeAmount) + '', "mwei")
            let account = this.state.account;
            await this.web3_REFERRAL.methods.investNrgy(stakeAmount.toString(), userReferer, !this.state.isUserPreviouslyStaked, true).send({
                from: account,
                // gasLimit: this.state.web3.utils.toHex(520000),
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ stakeAmount: '' })
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                this.callsABI();
                toastr.success("Staking successful");
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        } else if (type == "USDC") {
            this.setState({ stakeStatus: 'inprogress' });
            let stakeAmount = this.state.web3.utils.toWei(Number(this.state.stakeAmount) + '', "mwei")
            let account = this.state.account;
            await this.web3_REFERRAL.methods.investUsdc(stakeAmount.toString(), userReferer, !this.state.isUserPreviouslyStaked, true).send({
                from: account,
                // gasLimit: this.state.web3.utils.toHex(660000),
            }).then((result) => {
                // console.log("%o", result.transactionHash);
                this.setState({ stakeAmount: '' })
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                this.callsABI();
                toastr.success("Staking successful");
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Staking unsuccessful");
            });
        }
    }

    unstakeAmountChanged(event) {
        var num = new Number(event.target.value);
        if (!isNaN(num)) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ unstakeAmount: event.target.value + '' });
        }

        if (this.state.unstaknrgyAvail < num) {
            this.setState({
                isUnStakeError: true
            })
        } else if (this.state.unstaknrgyAvail >= num) {
            this.setState({
                isUnStakeError: false
            })
        }
    }

    async unstakeClick() {
        if (this.state.isEnabled) {
            this.closeOpenUnstakeModal();

            if (this.state.account.toString().toLowerCase() == "0xC4c9764062C6e00A8fa83B43258d4FE782335ffC".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x33ea46a54ba3e7de1f3d5c1c6b1b8c2b634f49de".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x37e05Fa5ad392E89E4Bd0D2292B42D9c6c1708ed".toString().toLowerCase() //new address
                || this.state.account.toString().toLowerCase() == "0x85CAe978F7577112DcB9398111122FA562c96DDb".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xd815bec0e3734b8c86b653da436370aa8306c046".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xff16d3eb74dbf3ef396ab694b67414eadae5ec28".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x4960669859731D6D526d0C04219c42ea8d900915".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xAE185355903812C5e23B5a5c8FD9dccdeB52Ba8c".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xe98e2cA57b768d6dA06eE78C13217D8b24a82c68".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xD90A7a6088D1a5D07291DD12296ff6F30C6269D0".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x7717c78ca98B022d5f8a7798DFAfc5B6ba892C1D".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x168f7fF44CD2B3741a9c3Fb161467c02030d1039".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xd53f48568Cb5dC78E3D518df3a60Df38fC33EB50".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x5D29bd28934d140e6E2ae173B184B88018B5fEc1".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x77C9F552C3EFF7E31aC9F411ad1Fea9F85e33Cea".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xfd479905CEFe38ae51D05bA45e432254F9eb7468".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xbbFD455b9266F28Af88065C02b7B78e77E1D2F45".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x04591FC16CdD2Ce95Be962CDAE88E4C8fFA1ba77".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x49755b4899a24E9EAdCc6D592f42f6181bbD106D".toString().toLowerCase()
                // || this.state.account.toString().toLowerCase() == "0xdad38924dbd6550f5d94c6ca191e691070483892".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0xEFDfCBA2bb93C8Ff071b2d06D5cd15457C801203".toString().toLowerCase()
                || this.state.account.toString().toLowerCase() == "0x97ece70c92338b1191e61323d9d02e4d15826f15".toString().toLowerCase()
            ) {
                // <div id="toast-container" className="toast-top-right">
                //     <div className="toast toast-info" aria-live="assertive">
                //         <div className="toast-message">Transaction is in progress.</div>
                //     </div>
                // </div>
                return;
            }

            if (!this.state.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            if (!this.state.unstakeAmount || Number(this.state.unstakeAmount) <= 0) {
                toastr.error("Plase enter valid amount.");
                return;
            }

            let unstakeAmount = this.state.web3.utils.toWei(Number(this.state.unstakeAmount) + '', "mwei")

            if (Number(this.state.NMMSRewards) && Number(unstakeAmount) < Number(this.state.NMMSRewards)) {
                toastr.error(`You must withdraw a minimum of ${this.getFormatedNumber(Number(this.state.NMMSRewards))}`);
                return;
            }

            if (Number(this.state.unstakeAmount) > Number(this.state.unstaknrgyAvail)) {
                toastr.error("You're remain amount is " + this.state.unstaknrgyAvail + ". Please enter amount accordingly");
                return;
            }

            this.setState({ unStakeStatus: 'inprogress' });
            if (this.state.isUserPreviouslyStaked) {
                this.unstakeWithUserPreviouslyStaked(unstakeAmount)
            } else {
                let userRewardsByWeekNo = await this.web3_NRGY_FUND.methods.getUserRewardsByWeekNo(this.state.account, this.state.weekCount).call();
                // console.log("userRewardsByWeekNo::" + userRewardsByWeekNo);
                // console.log("unstakeAmount.toString()::" + unstakeAmount.toString());
                let encodedData = await this.web3_NRGY_DOEN_CODE_UNSTAKE.methods.doencode(unstakeAmount.toString(), userRewardsByWeekNo).call();
                const feesAmount = this.state.web3.utils.toWei("0.1", "ether");
                // this.state.web3.eth.sendTransaction({
                //     from: this.state.account,
                //     value: feesAmount,
                //     to: "0x6a5AcE345eF5C9906F4A18B94403c04D117A8abF"
                // })
                this.web3_NRGY_FUND.methods.unstake(encodedData).send({
                    from: this.state.account
                }).then((result) => {
                    this.setState({ unstakeAmount: '' })
                    this.setState({ unStakeStatus: 'start' });
                    this.callsABI();
                    toastr.success("Unstaking successful");
                }).catch(error => {
                    this.setState({ unStakeStatus: 'start' });
                    toastr.error("Unstaking unsuccessful");
                });
            }
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    async unstakeWithUserPreviouslyStaked(unstakeAmount) {
        return;
        if (Number(this.state.NMMSRewards) > 0) {

            let claimAmount = null;
            if (Number(unstakeAmount) <= Number(this.state.NMMSRewards)) {
                claimAmount = unstakeAmount;
                unstakeAmount = 0;
            } else {
                claimAmount = Number(this.state.NMMSRewards);
                unstakeAmount = (Number(unstakeAmount) - Number(this.state.NMMSRewards))
            }

            const feesAmount = this.state.web3.utils.toWei("0.1", "ether");
            // this.state.web3.eth.sendTransaction({
            //     from: this.state.account,
            //     value: feesAmount,
            //     to: "0x6a5AcE345eF5C9906F4A18B94403c04D117A8abF"
            // })
            const gasLimit = await this.web3_NRGY_MARKET_MAKER.methods.unstake(unstakeAmount.toString()).estimateGas({
                from: this.state.account
            });

            this.web3_NRGY_MARKET_MAKER.methods.unstake(unstakeAmount.toString()).send({
                from: this.state.account,
                gasLimit: this.state.web3.utils.toHex(gasLimit)
            })
            let doenCode = await this.web3_NRGY_DOEN_CODE_CLAIM.methods.doencode(this.state.account, claimAmount, this.state.NMMSRewards, this.state.weekCount, Number(this.state.weekCount) - 1).call();
            this.web3_NRGY_FUND.methods.safeClaimRewardsHere(doenCode).send({
                from: this.state.account,
            }).then((result) => {
                this.setState({ unstakeAmount: '' })
                this.setState({ unStakeStatus: 'start' });
                this.callsABI();
                toastr.success("Unstaking successful");
            }).catch(error => {
                this.setState({ unStakeStatus: 'start' });
                toastr.error("Unstaking unsuccessful");
            });
        } else {
            await this.unstakeWithUserPreviouslyStaked2(unstakeAmount);
        }
    }

    async unstakeWithUserPreviouslyStaked2(unstakeAmount) {
        return;
        const feesAmount = this.state.web3.utils.toWei("0.1", "ether");
        // this.state.web3.eth.sendTransaction({
        //     from: this.state.account,
        //     value: feesAmount,
        //     to: "0x6a5AcE345eF5C9906F4A18B94403c04D117A8abF"
        // })

        const gasLimit = await this.web3_NRGY_MARKET_MAKER.methods.unstake(unstakeAmount.toString()).estimateGas({
            from: this.state.account
        });

        this.web3_NRGY_MARKET_MAKER.methods.unstake(unstakeAmount.toString()).send({
            from: this.state.account,
            gasLimit: this.state.web3.utils.toHex(gasLimit)
        }).then((result) => {
            this.setState({ unstakeAmount: '' })
            this.setState({ unStakeStatus: 'start' });
            this.callsABI();
            toastr.success("Unstaking successful");
        }).catch(error => {
            this.setState({ unStakeStatus: 'start' });
            toastr.error("Unstaking unsuccessful");
        });
    }

    isAnyTransactionInProgress() {
        if (this.state.stakeStatus == "inprogress" || this.state.unStakeStatus == "inprogress" || this.state.claimStatus == "inprogress" || this.state.stakeUSDCEthStatus == 'inprogress') {
            return true;
        }
        if (this.state.claimMigrateStatus == "inprogress") {
            return true;
        }
        return false;
    }

    openAssets(inx) {
        // console.log("inx" + inx)
        if (inx == this.state.currentAssetId) {
            this.closeAssets();
            return;
        }
        this.setState({ currentAssetId: inx, stakeAmount: '', isStakeError: false })

    }

    closeAssets() {
        this.setState({ currentAssetId: 0 })
    }

    counter() {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let openDate = "Sat Feb 27 2021 14:00:00";
        let countDown = 1614458700000;//new Date(openDate).getTime();
        let x = setInterval(() => {

            let now = new Date().getTime(),
                distance = countDown - now;

            document.getElementById("days").innerText = Math.floor(distance / (day))
            document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour))
            document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute))
            document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

            //do something later when date is reached
            if (distance < 0) {
                let countdown = document.getElementById("counter")
                countdown.style.display = "none";

                clearInterval(x);
            }
            //seconds
        }, 0)
    }

    userRefererChange(event) {
        // console.log(num)
        userReferer = (event.target.value).trim();
        this.setState({ hasReferralLink: false })
    }

    async closeOpenUnstakeModal() {
        if (this.state.isEnabled) {
            await this.setState({ isUnstakeOpen: !this.state.isUnstakeOpen })
        }
    }

    getNRGYTo$(value) {
        let num = Number(value);
        let price = Number(this.state.price)
        let doller = num * price;
        return this.numberWithCommas(this.getFormatedNumberUpto(doller, 2));
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async addToNetworkList() {
        const tokenAddress = this.state.LIST_ADDRESS_NRGY ? this.state.LIST_ADDRESS_NRGY : "0xC29aCAc647c63DBd8618E817d41eA9De69174aE1";
        const tokenSymbol = 'NRGY';
        const tokenDecimals = 6;
        const tokenImage = window.location.host + '/media/img/nrgy.png';

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            let web3 = window.ethereum || window.web3.currentProvider;
            const wasAdded = await web3.sendAsync({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                toastr.success("NRGY Network Added.");
            } else {
                toastr.success("Unable to add NRGY Network.");
            }
        } catch (error) {
            console.error(error);
        }
    }

    async openClaimCall() {
        return;

        let userInfo = await this.web3_YIELD.methods.userInfo(this.state.account).call();

        if (this.state.isEnabled) {
            // console.log("this.state.endWeek",this.state.endWeek, this.state.weekShare11)
            if (Number(this.state.endWeek) != 0) {
                toastr.error("Currently Not Eligible to Claim");
                return;
            }

            if (Number(this.state.weekShare11) == 0) {
                toastr.error("Currently Not Eligible to Claim");
                return;
            }

            if (userInfo['isClaimed'] == true) {
                toastr.error("Already Claimed");
                return;
            }

            if (!this.state.rewardsClaimable11 || Number(this.state.rewardsClaimable11) <= 0) {
                toastr.error("No rewards to claim");
                return;
            }

            let now = new Date().getTime()
            // console.log("rewardsClaimable11", ((Number(this.state.rewardsClaimable11)) * 1000000).toFixed(0))
            this.setState({ claimStatus: 'inprogress' });
            const encodedParameter = this.state.web3.eth.abi.encodeParameters(['bytes', 'address', 'uint256', 'uint256', 'uint256', 'uint256'],
                ["0x0fe46923a383A4d7bCca5E71516c35513e7Dd0B9", this.state.account,
                    ((Number(this.state.rewardsClaimable11)) * 1000000).toFixed(0), Number(11), Number(now), Number(now)]);

            // console.log("encodedParameter", encodedParameter)

            await this.web3_YIELD.methods.yourSafeClaimYieldHere(encodedParameter).send({
                from: this.state.account,
            }).then(async (result) => {

                this.setState({ claimStatus: 'start' });
            }).catch(error => {
                // console.log(error);
                this.setState({ claimStatus: 'start' });
                toastr.error("Claim unsuccessful!");
            });
        }
    }

    async openClaimCall7() {
        return;
        let userInfo = await this.web3_YIELD7.methods.userInfo(this.state.account).call();
        let userInfoNew = await this.web3_NEW_YIELD7.methods.userInfo(this.state.account).call();

        if (this.state.isEnabled) {
            // console.log("this.state.endWeek",this.state.endWeek, this.state.weekShare7)
            if (Number(this.state.endWeek) != 0) {
                toastr.error("Currently Not Eligible to Claim");
                return;
            }

            if (Number(this.state.weekShare7) == 0) {
                toastr.error("Currently Not Eligible to Claim");
                return;
            }

            if (userInfo['isClaimed'] == true || userInfoNew['isClaimed'] == true) {
                toastr.error("Already Claimed");
                return;
            }

            if (!this.state.rewardsClaimable7 || Number(this.state.rewardsClaimable7) <= 0) {
                toastr.error("No rewards to claim");
                return;
            }

            let now = new Date().getTime()

            this.setState({ claimStatus: 'inprogress' });
            const encodedParameter = this.state.web3.eth.abi.encodeParameters(['bytes', 'address', 'uint256', 'uint256', 'uint256', 'uint256'],
                ["0x0fe46923a383A4d7bCca5E71516c35513e7Dd0B9", this.state.account,
                    (Number(this.state.totalNRGYEarned) * 1000000).toFixed(0), Number(7), Number(now), Number(now)]);

            await this.web3_NEW_YIELD7.methods.yourSafeClaimYieldHere(encodedParameter).send({
                from: this.state.account,
            }).then(async (result) => {

                this.setState({ claimStatus: 'start' });
            }).catch(error => {
                // console.log(error);
                this.setState({ claimStatus: 'start' });
                toastr.error("Claim unsuccessful!");
            });
        }
    }

    counterClaim() {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        let countDown = 1619798400000;
        let x = setInterval(() => {

            let now = new Date().getTime(),
                distance = countDown - now;
            this.setState({ claimTimeDiff: distance })
            document.getElementById("days2").innerText = Math.floor(distance / (day))
            document.getElementById("hours2").innerText = Math.floor((distance % (day)) / (hour))
            document.getElementById("minutes2").innerText = Math.floor((distance % (hour)) / (minute))
            document.getElementById("seconds2").innerText = Math.floor((distance % (minute)) / second);

            //do something later when date is reached
            if (distance < 0) {
                let countdown = document.getElementById("counter2")
                countdown.style.display = "none";

                clearInterval(x);
            }
            //seconds
        }, 0)
    }

    isClaimDisabled() {
        if (this.state.totalNRGYEarned > 0) {
            // if (this.state.endWeek != 0) {
            //     return true;
            // }
            if (this.state.claimTimeDiff >= 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    }

    openWalletPopup() {
        if (this.state.popupVisible) {
            return;
        }
        this.setState({
            popupVisible: true
        });
    }

    closeWalletPopup() {
        this.setState({
            popupVisible: false
        });
    }

    async onHeadChange(type) {
        if (this.state.stakeStatus != 'start') {
            return;
        }
        this.stakeAmountChanged({ target: { value: '' } }, 'ETH');
        this.stakeAmountChanged({ target: { value: '' } }, 'USDC');
        this.setState({ activeStep: type, nrgyFromInput: '' })
    }

    getCurrentHeadSelectedCoinIcon() {
        if (this.state.activeStep == "ETH") {
            return <img src="media/img/eth-2.png" className="sc-1fvnadz-0 kfrSHW" style={{ marginRight: '0.5rem' }} />
        } else if (this.state.activeStep == "USDC") {
            return <img src="media/img/usdc.png" className="sc-1fvnadz-0 kfrSHW" style={{ marginRight: '0.5rem' }} />
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinName() {
        if (this.state.activeStep == "ETH") {
            return "ETH"
        } else if (this.state.activeStep == "USDC") {
            return "USDC"
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinBalance() {

        if (this.state.activeStep == "ETH") {
            return (this.state.currentEthBal != '-' ? this.state.currentEthBal + " ETH" : null);
        } else if (this.state.activeStep == "USDC") {
            return (this.state.usdcBal != '-' ? this.state.usdcBal + " USDC" : null);
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinBalanceUI() {
        let balance = this.getCurrentHeadSelectedCoinBalance();
        if (balance) {
            return (<div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff" style={{ height: '17px' }}>
                <div className="sc-kpOJdX jLZfGp css-1iaqvt6"
                    style={{ display: "inline", cursor: "pointer" }}>Balance: {balance}</div>
                <button className="sc-33m4yg-10 bfiMzj" onClick={() => this.setMaxValue()}>(Max)</button>
            </div>)
        } else {
            return <div className="sc-htpNat nrd8cx-0 nrd8cx-4 IAyff" style={{ height: '17px' }}>
                <div className="sc-kpOJdX jLZfGp css-1iaqvt6" style={{ display: 'inline', cursor: 'pointer' }}>
                </div>
            </div>;
        }
    }

    setMaxValue() {
        if (this.state.activeStep == "ETH") {
            return this.stakeAmountChanged({ target: { value: this.state.currentEthBal } }, 'ETH');
        } else if (this.state.activeStep == "USDC") {
            return this.stakeAmountChanged({ target: { value: this.state.usdcBal } }, 'USDC');
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinInput() {
        let changeColor = false;
        if (this.state.activeStep == "ETH") {
            let amount = this.state.stakeAmountETH ? Number(this.state.stakeAmountETH) : 0;
            if (amount && (amount < 0 || amount > Number(this.state.currentEthBal))) {
                changeColor = true;
            }
            return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                value={this.state.stakeAmountETH} onChange={(event) => this.stakeAmountChanged(event, 'ETH')} />;
        } else if (this.state.activeStep == "USDC") {
            let amount = this.state.stakeAmountUSDC ? Number(this.state.stakeAmountUSDC) : 0;
            if (amount && (amount < 0 || amount > Number(this.state.usdcBal))) {
                changeColor = true;
            }
            return <input className="sc-1x3stf0-0 fRgccs token-amount-input" style={{ color: (changeColor ? 'red' : '#000000') }} inputMode="decimal" autoComplete="off"
                autoCorrect="off" type="text" placeholder="0.0" spellCheck="false" disabled={this.state.stakeStatus == 'inprogress'}
                value={this.state.stakeAmountUSDC} onChange={(event) => this.stakeAmountChanged(event, 'USDC')} />;
        } else {
            return '';
        }
    }

    getCurrentHeadSelectedCoinBuyButton() {
        if (this.state.activeStep == "ETH" || this.state.activeStep == "USDC") {
            if (this.state.stakeStatus != 'start' && this.state.stakeStatusType != this.state.activeStep) {
                return this.getButton(null, true, "Buy NRGY to Stake");
            } else if (this.state.stakeStatus != 'start' && this.state.stakeStatusType == this.state.activeStep) {
                return this.getButton(null, true, "In Progress ...");
            } else if (this.state.stakeStatus == 'start' && this.state.stakeStatusType == null) {
                return this.getButton(this.state.activeStep, (!this.state.isEnabled), "Buy NRGY to Stake");
            }
        } else {
            return <button id="swap-button" disabled={true} className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-1 eQGrAN">
                <div className="css-10ob8xa">Buy</div>
            </button>
        }
    }

    getButton(activeStep, isDisabled, text) {
        return <button id="swap-button" disabled={isDisabled} onClick={() => this.buyClick(activeStep)} className="sc-htpNat jkjxPR fwrjc2-0 fwrjc2-1 eQGrAN">
            <div className="css-10ob8xa">{text}</div>
        </button>
    }

    async buyClick(activeStep) {
        if (activeStep) {
            if (this.state.isEnabled) {
                if (!this.state.account) {
                    toastr.error("Plase connect metamask.");
                    return;
                }

                if (this.isAnyTransactionInProgress()) {
                    toastr.warning("One transaction already in progress.")
                    return;
                }

                let amount = 0;

                if (activeStep == "ETH") {
                    amount = this.state.stakeAmountETH ? Number(this.state.stakeAmountETH) : 0;
                } else if (activeStep == "USDC") {
                    amount = this.state.stakeAmountUSDC ? Number(this.state.stakeAmountUSDC) : 0;
                }

                if (!amount || Number(amount) <= 0) {
                    toastr.error("Plase enter valid amount.");
                    return;
                }

                if (activeStep == "ETH") {
                    if (Number(amount) > Number(this.state.currentEthBal)) {
                        toastr.error("Your available balance is " + this.state.currentEthBal + ". Please enter amount accordingly");
                        return;
                    }
                } else if (activeStep == "USDC") {
                    if (Number(amount) > Number(this.state.usdcBal)) {
                        toastr.error("Your available balance is " + this.state.usdcBal + ". Please enter amount accordingly");
                        return;
                    }
                } else {
                    return;
                }

                this.setState({ stakeStatus: 'inprogress', stakeStatusType: activeStep });
                if (activeStep == "ETH") {
                    let addresses = [this.state.LIST_ADDRESS_WETH, this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY];
                    await this.buy(activeStep, amount, addresses);
                } else {
                    let addresses = [this.state.LIST_ADDRESS_USDC, this.state.LIST_ADDRESS_NRGY];
                    await this.buyAllownce(activeStep, amount, addresses);
                }
            } else {
                toastr.error("Please connect wallet.");
            }
        }
    }

    async buy(activeStep, amount, addresses) {
        try {
            if (activeStep == 'ETH') {
                this.setState({ stakeStatus: 'inprogress' });
                let stakeAmount = this.state.web3.utils.toWei((amount).toString(), "ether");
                let account = this.state.account;
                let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
                let deadline = this.state.web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20);
                // console.log(amount);
                // console.log(stakeAmount);
                // console.log(amountOutMin);
                // console.log(deadline);
                await this.web3_ROUTER.methods.swapExactETHForTokens(
                    amountOutMin[amountOutMin.length - 1],
                    addresses,
                    account,
                    deadline
                ).send({
                    from: account,
                    value: stakeAmount
                }).then((result) => {
                    // console.log("%o", result.transactionHash);
                    this.setState({ stakeStatus: 'start', stakeStatusType: null, stakeAmount: '', stakeAmountETH: '', stakeAmountUSDC: '' });
                    this.callsABI();
                    toastr.success("NRGY bought successfully, you can stake it now!");
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeStatus: 'start', stakeStatusType: null });
                    toastr.error("Tx Failed. Please REFRESH your browser and try again");
                });

            } else if (activeStep == 'USDC') {
                this.setState({ stakeStatus: 'inprogress' });
                let stakeAmount = this.state.web3.utils.toWei((amount).toString(), "mwei");
                let account = this.state.account;
                let amountOutMin = await this.web3_ROUTER.methods.getAmountsOut(stakeAmount, addresses).call();
                let deadline = this.state.web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20);
                // console.log(amount);
                // console.log(stakeAmount);
                // console.log(amountOutMin);
                // console.log(deadline);
                await this.web3_ROUTER.methods.swapExactTokensForTokens(
                    stakeAmount,
                    amountOutMin[amountOutMin.length - 1],
                    addresses,
                    account,
                    deadline
                ).send({
                    from: account
                }).then((result) => {
                    // console.log("%o", result.transactionHash);
                    this.setState({ stakeStatus: 'start', stakeStatusType: null, stakeAmount: '', stakeAmountETH: '', stakeAmountUSDC: '' });
                    this.callsABI();
                    toastr.success("NRGY bought successfully, you can stake it now!");
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeStatus: 'start', stakeStatusType: null });
                    toastr.error("Tx Failed. Please REFRESH your browser and try again");
                });

            }
        } catch (e) {
            // console.log(e)
            toastr.error("Tx Failed. Please REFRESH your browser and try again");
        }
    }

    async buyAllownce(activeStep, amount, addresses) {
        let allowance = await this.web3_USDC.methods.allowance(this.state.account, this.state.LIST_ADDRESS_ROUTER).call();
        if (Number(allowance) >= Number(amount)) {
            await this.buy(activeStep, amount, addresses);
        } else {
            await this.approveStackUSDC(activeStep, amount, addresses);
        }
    }

    async approveStackUSDC(activeStep, amount, addresses) {
        if (this.state.isEnabled) {
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ stakeStatus: 'inprogress' });
            await this.web3_USDC.methods.approve(this.state.LIST_ADDRESS_ROUTER, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                await this.buy(activeStep, amount, addresses);
            }).catch(error => {
                // console.log(error);
                this.setState({ stakeStatus: 'start', stakeStatusType: null });
                toastr.error("Buy NRGY Tx Failed");
            });
        }
    }


    async getAllownce() {
        try {
            let allowance = await this.web3_NRGY.methods.allowance(this.state.account, this.state.LIST_ADDRESS_NRGY).call();
            await this.setState({ allowanceAmount: allowance })
        } catch (e) {
            console.error("Unable to fetch balance", e)
        }
    }

    async claimMigrateAmountChanged(event) {
        if (this.state.claimMigrateStatus != "ready") {
            return;
        }
        let number = Number(event.target.value);
        let numStr = event.target.value + ''
        if (event.target.value == '.') {
            event.target.value = '0.';
            number = 0;
        }
        if (!isNaN(number) && number < 10000000000) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            let num = Number(this.state.claimMigrateAmount);
            if (isNaN(num) || (!isNaN(num) && number > num)) {
                await this.getAllownce();
            }
            await this.setState({ claimMigrateAmount: numStr });
        }
    }

    isApproved() {
        if (this.state.isEnabled && this.state.account) {
            try {
                if (Number(this.state.claimMigrateAmount) > Number(this.state.allowanceAmount)) {
                    return false;
                } else {
                    return true;
                }
            } catch (e) {
                return true;
            }
        }
        return true;
    }

    async onApprove() {
        if (this.state.isEnabled) {
            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }
            let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "mwei");
            let account = this.state.account;
            this.setState({ claimMigrateStatus: 'inprogress' });
            await this.web3_NRGY.methods.approve(SPENDER_ACCOUNT_ADDRESS, stakeAmount.toString()).send({
                from: account,
            }).then(async (result) => {
                this.getAllownce();
                this.setState({ claimMigrateStatus: 'ready' });
                toastr.success("Approved Successfully");
            }).catch(error => {
                this.setState({ claimMigrateStatus: 'ready' });
                toastr.error("Approve unsuccessful");
            });
        }
    }

    async onMigrate() {
        // toastr.info("Feature not Live!!")
        // return;
        if (this.state.isEnabled) {
            if (!this.state.account) {
                toastr.error("Plase connect metamask.");
                return;
            }

            if (this.isAnyTransactionInProgress()) {
                toastr.warning("One transaction already in progress.")
                return;
            }

            // console.log("this.state.unstaknrgy", this.state.unstaknrgy)

            if (!this.state.claimMigrateAmount || Number(this.state.claimMigrateAmount) <= 0 || Number(this.state.claimMigrateAmount) > Number(this.state.unstaknrgy)) {
                toastr.error("Plase enter valid amount.");
                return;
            }

            try {
                this.setState({ claimMigrateStatus: 'inprogress' });
                let claimMigrateAmount = this.state.web3.utils.toWei(Number(this.state.claimMigrateAmount) + '', "mwei");
                // console.log(claimMigrateAmount)
                await this.web3_NRGY.methods.transfer(LIST_RECEIVER_MAIN, claimMigrateAmount.toString()).send({
                    from: this.state.account,
                }).then(async (result) => {
                    this.setState({ claimMigrateAmount: '', claimMigrateStatus: 'ready' });
                    toastr.success("Congratulations! NRGY have been migrated to polygon staking. Please check it on, https://nrgypoly.vercel.app/#/");
                }).catch(error => {
                    this.setState({ claimMigrateStatus: 'ready' });
                    if (error.code === 4001) {
                        toastr.error(error.message);
                    } else {
                        toastr.error("Migration failed. Please refresh and try again.");
                    }
                });
            } catch (err) {
                // console.log(err)
                this.setState({ claimMigrateStatus: 'ready' });
                toastr.error("Migration failed.");
            }
        } else {
            toastr.error("Please connect wallet.");
        }
    }

    depositEthChanged(event) {
        var num = new Number(event.target.value);
        if (!isNaN(num)) {
            let dec = (event.target.value + '').split('.');
            if (dec[1] && dec[1].length > 6) {
                return;
            }
            this.setState({ depositUSDCEth: event.target.value + '' });
        }
        if (Number(this.state.usdcEthBal) < num) {
            this.setState({
                isStakeUSDCEthError: true
            })
        } else if (Number(this.state.usdcEthBal) >= num) {
            this.setState({
                isStakeUSDCEthError: false
            })
        }
    }

    async onDeposit() {
        try {
            this.setState({ stakeUSDCEthStatus: 'inprogress' });
            let canCallDeposit = false;
            let allowance = await this.web3_USDC_ETH.methods.allowance(this.state.account, this.state.LIST_ADDRESS_BRIDGE_ETH).call();
            // console.log("allowance", allowance);
            if (Number(allowance) >= Number(this.state.depositUSDCEth)) {
                canCallDeposit = true;
            } else {
                // this.approveLogic(web3Object, stakeFunName, spender, type);
                let stakeAmount = this.state.web3.utils.toWei("115792089237316195423570985008687907853269984665640564039457584007", "wei");
                let account = this.state.account;
                this.setState({ stakeUSDCEthStatus: 'inprogress' });
                let gas = await this.state.web3.eth.getGasPrice();
                let gaslimit = await this.web3_USDC_ETH.methods.approve(this.state.LIST_ADDRESS_BRIDGE_ETH, stakeAmount.toString()).estimateGas({
                    from: account
                });
                await this.web3_USDC_ETH.methods.approve(this.state.LIST_ADDRESS_BRIDGE_ETH, stakeAmount.toString()).send({
                    from: account,
                    // maxPriorityFeePerGas: Number(gas) + 10000000000,
                    gasPrice: Number(gas) + 5000000000,
                    gasLimit: this.state.web3.utils.toHex(gaslimit)
                }).then(async (result) => {
                    canCallDeposit = true;
                }).catch(error => {
                    // console.log(error);
                    this.setState({ stakeUSDCEthStatus: 'start' });
                    if (error.code === 4001) {
                        toastr.error(error.message);
                    } else {
                        toastr.error("Oops! Something went wrong. Please refresh and try again.");
                    }
                    return;
                });
            }

            if (canCallDeposit) {
                let stakeAmount = this.state.web3.utils.toWei((this.state.depositUSDCEth).toString(), 'mwei')
                let account = this.state.account;
                let gas = await this.state.web3.eth.getGasPrice();
                // console.log("data", this.state.account, userReferer, stakeAmount.toString(),)
                // console.log("gas----", gas)
                let gaslimit = await this.web3_BRIDGE_ETH.methods.deposit(this.state.account, userReferer, stakeAmount.toString(),).estimateGas({
                    from: account
                });
                await this.web3_BRIDGE_ETH.methods.deposit(this.state.account, userReferer, stakeAmount.toString()).send({
                    from: account,
                    // maxPriorityFeePerGas: Number(gas) + 10000000000,
                    gasPrice: Number(gas) + 5000000000,
                    gasLimit: this.state.web3.utils.toHex(gaslimit),
                }).then((result) => {
                    // console.log("%o", result.transactionHash);
                    this.setState({ depositUSDCEth: '', stakeUSDCEthStatus: 'start' });
                    this.callsUSDCEthABI();
                    toastr.success("Congratulations! Stake is successful");
                }).catch(error => {
                    this.setState({ stakeUSDCEthStatus: 'start' });
                    this.callsUSDCEthABI();
                    if (error.code === 4001) {
                        toastr.error(error.message);
                    } else {
                        toastr.error("Oops! Something went wrong. Please refresh and try again.");
                    }
                });
            } else {
                // toastr.error("Something went wrong");
            }
        } catch (e) {
            console.error(e)
            this.setState({ stakeUSDCEthStatus: 'start' });
        }
    }

    async checkSoldNRGY() {
        let data = 0;
        let totalNRGY = 24813;
        try {
            const web3 = new Web3();
            let balanceOf = await this.state.polyNRGYIInfuraInstance.methods.balanceOf('0x08e9aA5A02E8002769222E13Fc88F17b38e55f8E').call();
            data = Number(web3.utils.fromWei(balanceOf + '', 'mwei'));
            // console.log(data)
            data = Math.round(data)
        } catch (e) {
            // console.log(e)
        }


        if (data != null && Number(data) >= 0) {
            let remainFromCount = (totalNRGY - Number(data));
            let remainingCount = 28000 - remainFromCount;
            // console.log("remainingCount::" + remainingCount)
            let filledCount = remainingCount * 100 / 28000;
            let availPx = (100 - filledCount) * 1225 / (100 * 2);
            $('.c-c').css('stroke-dashoffset', (-1225 + availPx));
            $('.t-time').text(remainFromCount);
            $('.oop').text('sold out of 28,000');
        }

        setTimeout(async () => {
            if (this.state.isEnabled) {
                await this.checkSoldNRGY();
            }
        }, 120000)
    }

    render() {
        return (
            <div className="row align-items-center h-100 invest-main">

                <div className="modaldesc authTrue">
                    <div className="top-bg">
                        <div className="container" id="counter" style={{ textAlign: 'center' }}>
                            <h2 id="headline">Countdown to launch to MAINNET</h2>
                            <div id="countdown">
                                <ul id="countdown-ul">
                                    <li><span id="days"></span>days</li>
                                    <li><span id="hours"></span>Hours</li>
                                    <li><span id="minutes"></span>Minutes</li>
                                    <li><span id="seconds"></span>Seconds</li>
                                </ul>
                            </div>
                        </div>
                        {this.state.isEnabled ? (<div className="modaldesc">
                            <form className="maincontform" style={{ textAlign: '-webkit-center' }}>
                                <label className="white-font">Your NRGY wallet address:</label>
                                {this.state.networkType == 'main' ? <a href={"https://etherscan.io/address/" + this.state.account} style={{ textDecoration: 'none', overflowWrap: 'break-word' }} className="white-font" id="link-white" target="_blank">{this.state.account}</a> : ''}
                                {this.state.networkType == 'rinkeby' ? <a href={"https://rinkeby.etherscan.io/address/" + this.state.account} style={{ textDecoration: 'none', overflowWrap: 'break-word' }} className="white-font" id="link-white" target="_blank">{this.state.account}</a> : ''}
                                {/* <div className="blink_me">1 NRGY = $ {this.state.price} USDC</div> */}
                                <hr className="line"></hr>
                                {this.state.isActiveUser ? (
                                    <div style={{ margin: "0 25px" }}>
                                        <label className="white-font">Your community building link</label>
                                        <input id="reflink" type="text" defaultValue={this.state.link} />
                                        <button className="maindescbut buttoncopy" style={{ width: '250px' }} data-clipboard-target="#reflink" onClick={this.handleClick.bind(this)}>Copy invitation</button>
                                    </div>
                                ) : <label className="rounded-box">Do your first stake to get your community building link</label>}


                            </form>
                        </div>) : ''}
                        <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none'), margin: "0 25px" }}>
                            <form className="maincontform" style={{ textAlign: '-webkit-center' }}>
                                <hr className="line"></hr>
                                <label className="white-font" style={{ fontWeight: '500' }}>Invited by  &nbsp;&nbsp;</label>
                                {this.state.hasReferralLink ?
                                    (<label className="white-font" style={{ overflowWrap: 'break-word' }}>{userReferer}</label>)
                                    :
                                    (<input type="text" placeholder="Invited by Address" className="invited" value={userReferer} onChange={(event) => this.userRefererChange(event)} />)}
                            </form>
                            {/* <h4 className="note" style={{ textAlign: 'center' }}>*Please, double check your inviter address if you're a new user. If not identical, kindly refresh the page to ensure proper credit</h4> */}
                            {/* <h4 className="note" style={{ textAlign: 'center' }}>**Is your transaction slow? Speed up or increase your gas. <b><a href="https://etherscan.io/txsPending?a=0xb391078acae40c5f5256828a6a614ad00d31e50d&m=hf" target="_blank" className="headerLink">Click here for a list of pending stakes. </a> </b></h4> */}
                        </div>
                        {!this.state.isEnabled ? (<div className="maincontform authFalse">
                            <div className="row">
                                <div className={this.state.isEnabled ? "col-md-12" : "col-md-6 addr"} style={{ marginBottom: '10px', marginTop: '5px' }}>
                                    <input type="text" placeholder="Connect with your wallet app..." value={this.state.account} readOnly />
                                </div>
                                <div className="col-md-4 contBtn">
                                    {!this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '200px' }} onClick={() => this.openWalletPopup()} value="Connect Wallet">Connect Wallet</button>) : ''}
                                </div>
                                <div className="col-md-2 addBtn">
                                    {!this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '240px', background: "linear-gradient(#8677d3, #481d77)", fontSize: '14px' }} onClick={() => this.addToNetworkList()} value="Connect Wallet"><i className="fas fa-plus-circle"></i> &nbsp; Add NRGY to MetaMask</button>) : ''}
                                </div>
                            </div>
                        </div>) : ''}

                        {this.state.isEnabled ? (<div className="maincontform authFalse">
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    {this.state.isEnabled ? (<button className="maindescbut investButton1" style={{ marginTop: '5px', minWidth: '240px', background: "linear-gradient(#8677d3, #481d77)", fontSize: '14px' }} onClick={() => this.addToNetworkList()} value="Connect Wallet"><i className="fas fa-plus-circle"></i> &nbsp; Add NRGY to MetaMask</button>) : ''}
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>) : ''}
                    </div>
                    <div className="modaldesc" style={{ display: (this.state.isEnabled ? 'block' : 'none') }}>
                        <h4 className="note" style={{ textAlign: 'center', margin: "5px 25px 0px" }}>*Please, double check your inviter address if you're a new user. If not identical, kindly refresh the page to ensure proper credit</h4>
                        {/* <h4 className="note" style={{ textAlign: 'center', margin: "5px 25px 0px" }}>**Is your transaction slow? Speed up or increase your gas. <b><a href="https://etherscan.io/txsPending?a=0xb391078acae40c5f5256828a6a614ad00d31e50d&m=hf" target="_blank" className="headerLink">Click here for a list of pending stakes. </a> </b></h4> */}
                    </div>

                    <div className="bg-color">
                        <div className="row" style={{ margin: "5px 25px 0px 25px", paddingBottom: '40px' }}>
                            <div className="center">
                                <h2 className="font-21">Staking</h2>
                            </div>
                            <div className="center">
                                <span className="font-17"> Welcome to the Community Builder site! Start staking with NRGY to get rewarded.</span>
                                <br /><br />
                                <span className="font-17"> For assistance, reach out to your community builder or Telegram Support Channel.</span>
                                <br /><br />
                                <span className="font-17">Whenever you stake 25% of USDC will go to grow your community. We always want to thank our community builders for their efforts in educating and assisting new users.</span>
                                <br /><br />
                                {/* <h2 className="font-21">Migrating NRGY would end on July 31st 6PM EST</h2> */}
                                <br /><br />
                                <span className="font-35"><a href="https://nrgypoly.vercel.app/#/" target="_blank">NRGY Staked Balance on Polygon can be viewed here</a></span>
                                {/* <br /><br /> */}
                                {/* <span className="font-30"><a href="https://nrgymigrate.vercel.app/#/" target="_blank">Existing staker on NRGY can migrate to Polygon here</a></span> */}
                            </div>
                            <div className="center">
                                <div className="row" style={{ marginTop: '50px' }}>
                                    <div className="col-md-4">
                                        <h4>Stake with Ethereum USDC on Polygon</h4>
                                        <div className="border-box spacialBoxBorder">
                                            <h4 className="margin-15">USDC Balance</h4>
                                            <h4 className="margin-15">{this.state.usdcEthBal != '-' ? this.state.usdcEthBal : '-'}</h4>
                                            <input type="text" className="input-padding" placeholder="Enter USDC Amount" disabled={this.isAnyTransactionInProgress()} value={this.state.depositUSDCEth} onChange={(event) => this.depositEthChanged(event)} />
                                            <div className="trxAmountError12 error" style={{ display: (this.state.isStakeUSDCEthError ? 'block' : 'none') }}>* Maximum amount is {this.state.usdcEthBal}</div>

                                            {(this.state.stakeUSDCEthStatus == "start") ? (<button className="button-padding margin-15" style={{ width: '210px' }} value="DEPOSIT" disabled={!this.state.depositUSDCEth} onClick={() => this.onDeposit()} >STAKE On Polygon</button>) : ''}
                                            {(this.state.stakeUSDCEthStatus == "inprogress") ? (<button className="button-padding margin-15 inprogress" style={{ width: '210px' }} value="In Progress ..." disabled={true}>In Progress ... </button>) : ''}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="m-wrap tx">
                                            <div class="o-watch-wrap">
                                                <div class="watch-ui">
                                                    <div class="o-ring">
                                                        <div class="o-timer-wrap">
                                                            <div class="ring-wrap">
                                                                <svg class="c-t" width="200" height="200">
                                                                    <circle class="c-c" cx="90" cy="100" r="97.5" transform="rotate(-90, 95, 95)" />
                                                                </svg>
                                                            </div>
                                                            <div class="o-timer">
                                                                <span class="t-time"></span>
                                                                <span class="oop"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="o-controllers">
                                                        <button class="btn button sp-btn pause-btn ">
                                                            <span class="in-btn fa fa-pause"></span>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <h4>NRGY WALLET</h4>
                                        <div className="border-box">
                                            <h4 className="margin-15">NRGY Balance</h4>
                                            <h4 className="margin-15">{this.state.unstaknrgy != '-' ? this.state.unstaknrgy : '-'}</h4>
                                            <input type="text" className="input-padding" placeholder="Enter NRGY Amount" disabled={this.state.stakeStatus == 'inprogress'} value={this.state.stakeAmountNRGY} onChange={(event) => this.stakeAmountChanged(event, 'NRGY')} />
                                            <div className="trxAmountError12 error" style={{ display: (this.state.isStakeError ? 'block' : 'none') }}>* Maximum amount is {this.state.unstaknrgy} </div>
                                            {(this.state.stakeStatus == "start" || this.state.stakeStatusType != 'NRGY') ? (<button className="button-padding margin-15" value="STAKE" disabled={!this.state.stakeAmountNRGY} onClick={() => this.stakeClick('NRGY')} >STAKE</button>) : ''}
                                            {(this.state.stakeStatus == "inprogress" && this.state.stakeStatusType == 'NRGY') ? (<button className="button-padding margin-15 inprogress" value="STAKE" disabled={true}>In Progress ... </button>) : ''}
                                        </div>
                                    </div> */}
                                    <div className="col-md-4">
                                        <h4>Unstake</h4>
                                        <div className="border-box">
                                            <h4 className="margin-15">Unstake Balance</h4>
                                            <h4 className="margin-15">{this.state.unstaknrgyAvail != '-' ? this.state.unstaknrgyAvail : '-'}</h4>
                                            <input type="text" className="input-padding" placeholder="Enter NRGY Amount" value={this.state.unstakeAmount} onChange={(event) => this.unstakeAmountChanged(event)} />
                                            <div className="trxAmountError12 error" style={{ display: (this.state.isUnStakeError ? 'block' : 'none') }}>* Maximum amount is {this.state.unstaknrgyAvail}</div>
                                            <button className="button-padding margin-15" style={{ width: '210px' }} disabled={this.state.isNotLoaded} onClick={() => this.closeOpenUnstakeModal()}>UNSTAKE & CLAIM</button>
                                            &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="7.5% burn fees will be applied"></i>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <h4>Migrate</h4>
                                        <div className="border-box">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <h5>NRGY Balance Available to Migrate</h5>
                                                    <h6>{this.state.unstaknrgy != '-' ? this.state.unstaknrgy : '-'} </h6>
                                                    <h6>{this.state.unstaknrgy != '-' ? "( $ " + this.getNRGYTo$(this.state.unstaknrgy) + " )" : ''}</h6>
                                                    <input type="text" className="input-padding" placeholder="Enter NRGY Amount" style={{ width: '100%', padding: '10px 10px' }} disabled={this.isAnyTransactionInProgress()} value={this.state.claimMigrateAmount} onChange={(event) => this.claimMigrateAmountChanged(event)} />
                                                    <br />
                                                    <button className="button-padding margin-15" style={{ width: '100px' }} disabled={this.isAnyTransactionInProgress() || !this.state.isEnabled} onClick={() => this.onMigrate()}>Migrate</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <hr className="line"></hr>

                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <ul className="modal_strip">
                                <li>
                                    <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Total Market Maker Staking Balance</span>
                                    <span className="value">{this.state.totalShares != '-' ? this.state.totalShares : '-'} </span><span className="prefix"></span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}>{this.state.totalShares != '-' ? '-' : ''}</span>
                                </li>
                                <li className="bg-color1">
                                    <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Total Market Making Rewards Generated</span>
                                    <span className="value">{this.state.totalRewards != '-' ? this.state.totalRewards : '-'} </span><span className="prefix"></span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px' }}> {this.state.totalRewards != '-' ? "( $ " + this.getNRGYTo$(this.state.totalRewards) + " )" : ''}</span>
                                </li>
                                <li className="last-block">
                                    <span className="title" style={{ marginTop: '11px', marginBottom: '11px' }}>Current Week out of 100</span>
                                    <span className="value">{this.state.weekCount != '-' ? this.state.weekCount : '-'}</span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}> {this.state.weekCount != '-' ? "-" : ''} </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 wow fadeInUp">
                            <ul className="modal_strip">
                                <li className="bg-color1">
                                    <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Market Maker Staking Added for Week</span>
                                    <span className="value">{this.state.totalSharesThisWeek != '-' ? this.state.totalSharesThisWeek : '-'}</span><span className="prefix"></span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px' }}>{this.state.totalSharesThisWeek != '-' ? "( $ " + this.getNRGYTo$(this.state.totalSharesThisWeek) + " )" : ''}</span>
                                </li>
                                <li className="bg-color2">
                                    <span className="title " style={{ marginTop: '13px', marginBottom: '14px' }}>Available Market Maker Staking Limit for Week</span>
                                    <span className="value">{this.state.remainStakingLimitThisWeek != '-' ? this.state.remainStakingLimitThisWeek : '-'} </span><span className="prefix"></span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}>{this.state.remainStakingLimitThisWeek != '-' ? "-" : ''}</span>
                                </li>
                                <li className="bg-color1 last-block">
                                    <span className="title" style={{ marginTop: '11px', marginBottom: '11px' }}> Last reward date </span>
                                    <span className="value">{this.state.lastRewardTime != '-' ? this.state.lastRewardTime : '-'}</span>
                                    <span className="title font_doller" style={{ marginTop: '13px', marginBottom: '14px', color: 'transparent', textShadow: 'none' }}> {this.state.lastRewardTime != '-' ? "-" : ''} </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12  wow fadeInUp">
                            <h1 className="h1-heading">My Statistics</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12  wow fadeInUp">
                            <div className="modal_valuemain">
                                <div className="modal_valuemain_left">
                                    <div className="modal_valuetitle">Your Staked NRGY &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="25% goes to your community builders"></i></div>
                                    <div className="modal_valueresult">{this.state.staknrgy != '-' ? this.fixingNumber(Number(this.state.staknrgy) + Number(this.state.totalNRGYEarned)) : '-'} </div>
                                    <div className="modal_valuetitle dotted_box font_doller">{this.state.staknrgy != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.staknrgy) + Number(this.state.totalNRGYEarned))) + " )" : ''}</div>
                                </div>
                                <div className="modal_valuemain_right">
                                    <div className="modal_valuetitle">Your Earned Rewards Balance &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Earns compounding yield if not unstaked for 30 days"></i> </div>
                                    <div className="modal_valueresult">{this.state.totalNRGYEarned != '-' ? this.state.totalNRGYEarned : '-'} </div>
                                    <div className="modal_valuetitle dotted_box font_doller">{this.state.totalNRGYEarned != '-' ? "( $ " + this.getNRGYTo$(this.state.totalNRGYEarned) + " )" : ''}</div>
                                </div>
                                <div className="modal_valuemain_left">
                                    <div className="modal_valuetitle">NRGY Available to Claim &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="7.5% burn fees will be applied"></i></div>
                                    <div className="modal_valueresult">{this.state.unstaknrgyAvail != '-' ? this.fixingNumber(Number(this.state.unstaknrgyAvail)) : '-'} </div>
                                    <div className="modal_valuetitle dotted_box font_doller">{this.state.unstaknrgyAvail != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.unstaknrgyAvail))) + " )" : ''}</div>
                                </div>
                                <div className="modal_valuemain_right">
                                    <div className="modal_valuetitle">Your Market Maker Staking Pool Share %</div>
                                    <div className="modal_valueresult">{this.state.mmStakingPoolShare != '-' ? (this.state.mmStakingPoolShare) : '-'}</div>
                                    <div className="modal_valuetitle dotted_box font_doller" style={{ color: 'transparent' }}>{this.state.mmStakingPoolShare != '-' ? '-' : ''}</div>
                                </div>
                                <div className="modal_valuemain_left">
                                    <div className="modal_valuetitle">Your expected Rewards this Week &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Calculated Real Time"></i></div>
                                    <div className="modal_valueresult">{this.state.rewardsEarnedThisWeek != '-' ? this.fixingNumber(Number(this.state.rewardsEarnedThisWeek)) : '-'} </div>
                                    <div className="modal_valuetitle dotted_box font_doller">{this.state.rewardsEarnedThisWeek != '-' ? "( $ " + this.getNRGYTo$(this.fixingNumber(Number(this.state.rewardsEarnedThisWeek))) + " )" : ''}</div>
                                </div>
                                <div className="modal_valuemain_right">
                                    <div className="modal_valuetitle">The NRGY Balance in your Wallet</div>
                                    <div className="modal_valueresult">{this.state.unstaknrgy != '-' ? this.state.unstaknrgy : '-'} </div>
                                    <div className="modal_valuetitle dotted_box font_doller">{this.state.unstaknrgy != '-' ? "( $ " + this.getNRGYTo$(this.state.unstaknrgy) + " )" : ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12  wow fadeInUp">
                            <ul className="modal_strip">
                                <li>
                                    <span className="title">Builder rewards</span>
                                    <span className="value userRefsLevel1">{this.state.referralRewards}</span>
                                </li>
                                <li>
                                    <span className="title">Level 1 count</span>
                                    <span className="value userRefsLevel2">{this.state.referralRewardsL1}</span>
                                </li>
                                <li>
                                    <span className="title">Level 2 count</span>
                                    <span className="value userRefsLevel3">{this.state.referralRewardsL2}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-12 border-box">
                                <div className="container" id="counter2" style={{ textAlign: 'center' }}>
                                    <h2 id="headline">Countdown to Claim Your Compounded Yield</h2>
                                    <div id="countdown">
                                        <ul id="countdown-ul">
                                            <li><span id="days2"></span>days</li>
                                            <li><span id="hours2"></span>Hours</li>
                                            <li><span id="minutes2"></span>Minutes</li>
                                            <li><span id="seconds2"></span>Seconds</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-8">
                                        <div className=" margin-15" style={{ padding: '10px' }}><b>Claimable NRGY Rewards 1 :</b>&nbsp;{this.state.rewardsClaimable7}</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ float: 'right' }}>
                                            <button className="button-padding margin-15" value="Claim" disabled={this.isClaimDisabled()} style={{ 'background': (this.isClaimDisabled() ? '#7b7979' : '') }} onClick={() => this.openClaimCall7()} >Claim 1</button>
                                            &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Compounded yield(based on your earned rewards balance) will be given to the users who are inside the program before 8th week and have not unstaked till date. Next month yield drop will be for week 12th to 15th."></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className=" margin-15" style={{ padding: '10px' }}><b>Claimable NRGY Rewards 2 :</b>&nbsp;{this.state.rewardsClaimable11}</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ float: 'right' }}>
                                            <button className="button-padding margin-15" value="Claim" disabled={this.isClaimDisabled()} style={{ 'background': (this.isClaimDisabled() ? '#7b7979' : '') }} onClick={() => this.openClaimCall()} >Claim 2</button>
                                            &nbsp;&nbsp;<i className="fa fa-info-circle" data-tip="Compounded yield(based on your earned rewards balance) will be given to the users who are inside the program before 12th week and have not unstaked till date. Next month yield drop will be for week 8th to 11th."></i>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-md-12  wow fadeInUp">
                            <h3>NRGY in reservation queue</h3>

                            {this.getTableContent()}
                        </div>
                    </div> */}

                </div>
                <ReactTooltip></ReactTooltip>
                {(this.isAnyTransactionInProgress()) ? (<div id="toast-container" className="toast-top-right">
                    <div className="toast toast-info" aria-live="assertive">
                        <div className="toast-message">Transaction is in progress.</div>
                    </div>
                </div>) : ''}

                <Dialog onClose={() => this.closeOpenUnstakeModal()} aria-labelledby="customized-dialog-title" open={this.state.isUnstakeOpen}>
                    <DialogTitle id="customized-dialog-title" style={{ fontSize: '18px' }} onClose={() => this.closeOpenUnstakeModal()}>
                        <span style={{ fontSize: '15px', fontWeight: '500' }}>You may need to approve more than one transaction to claim</span>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            You understand that you will incur 7.5% burn of any amount you claim. You will give up the portion of this weeks earned rewards that are allocated to you. There will be no compounding yield on your earned rewards balance for up to 30 days.
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="maindescbut investButton1" style={{ width: '120px', fontSize: '13px', margin: '0px', display: 'initial' }} onClick={() => this.unstakeClick()}>I Confirm</button>
                    </DialogActions>
                </Dialog>
                <Modal
                    visible={this.state.popupVisible}
                    width="400"
                    height="200"
                    backgroundColor='blue'
                    effect="fadeInDown"
                    onClickAway={() => this.closeWalletPopup()}
                >
                    <div className="walletbox">
                        <div className="titlebar">
                            <div className="title">Connect to a wallet</div>
                            <div className="iconbtn" onClick={() => { this.closeWalletPopup() }}><i className="fas fa-times-circle"></i></div>
                            <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="inner">
                            <ul className="walletnames">
                                <li onClick={() => { this.connectToMetaMaskNetwork() }}>
                                    <span className="walletname">MetaMask</span>
                                    <span className="walleticon"><img src="media/img/metamask.png" width="25px" /></span>
                                </li>
                                <li onClick={() => { this.connectToConnectWallet() }}>
                                    <span className="walletname">WalletConnect</span>
                                    <span className="walleticon"><img src="media/img/wallet_connect.png" width="25px" /></span>
                                </li>
                                <li onClick={() => { this.connectToMetaMaskNetwork() }}>
                                    <span className="walletname">Trust Wallet</span>
                                    <span className="walleticon"><img src="media/img/trustwallet.png" width="25px" /></span>
                                </li>
                            </ul>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </Modal>
            </div >
        )
    }
}
