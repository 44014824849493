import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import FAQ from './components/faq/faq';
import Invest from './components/invest/Invest';
import history from './history';

export default (prop) => (
    <HashRouter history={history} forceRefresh={true}>
        <Switch>
            {/* <Route exact path="/" component={() => <Main values={prop.data} history={history} />} /> */}
            <Route exact path="/" component={() => <Invest values={prop.data} history={history} />} />
            <Route exact path="priority-staking" component={() => <Invest values={prop.data} history={history} />} />
            <Route exact path="/faqs" component={() => <FAQ values={prop.data} history={history} />} />
            <Route component={() => <Invest values={prop.data} history={history}  />} />
        </Switch>
    </HashRouter>
);
