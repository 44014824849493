import React, { Component } from "react";
import { BrowserRouter, HashRouter, Link } from 'react-router-dom';
import './App.css';
import Routes from './Routes';

class App extends Component {

  constructor() {
    super();
    this.state = {

    }

    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentDidMount() {

    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  componentCleanup() {
    localStorage.clear();
  }

  handleClick(e) {
    e.preventDefault();
  }


  render() {
    return (
      <div>
        <HashRouter>
          <div className="main-container new-menu">

            <div className="row" style={{ background: '#fff' }}>
              <div className="col-xs-9"><div className="blink_me" id="blink_me"></div></div>
              <div className="col-xs-3">
                <a href="https://t.me/NRGYDEFI" target="_blank" className="socwrap">
                  <i className="fab fa-telegram"></i>
                  {/* <span className="socdesc">Telegram</span> */}
                </a>

                {/* <a href="https://t.me/NRGYDEFI" target="_blank" className="socwrap">
                  <i className="fab fa-twitter round-font"></i> */}
                  {/* <span className="socdesc">Telegram</span> */}
                {/* </a> */}
              </div>
            </div>
            <div className="row border" style={{ width: '100%', margin: '0' }}>
              <header>
                <div >
                  <div className="row" style={{ width: '100%' }}>
                    <div className="col-xs-12 ">
                      <div className="header-inner-wrap">
                        <div className="left">
                          <div className="logo">
                            <h1 style={{ margin: '0', fontWeight: '400' }}>Community<b> Builder</b></h1>
                          </div>
                          <div className="mobile-nav-button">
                            <div className="mobile-nav-button__line"></div>
                            <div className="mobile-nav-button__line"></div>
                            <div className="mobile-nav-button__line"></div>
                          </div>
                        </div>
                        <div className="right">

                          <nav className="menu">
                            <ul itemScope="" itemType="http://www.schema.org/SiteNavigationElement">
                              <li itemProp="name"><a itemProp="url" href="#">Staking</a></li>
                              <li itemProp="name"><a itemProp="url" href="media/files/NRGY_Overview.pdf" target="_blank">Overview</a></li>
                              <li itemProp="name"><a itemProp="url" href="media/files/NRGY%20Minting%20Schedule.pdf" target="_blank">Minting Schedule</a></li>
                              <li itemProp="name"><a itemProp="url" href={"https://v2.info.uniswap.org/pair/0x51bf9908dac4a283d017edc071d954ec3a4b1376"} target="_blank">Uniswap</a></li>
                              <li itemProp="name"><a itemProp="url" href="/#/faqs" target="_blank">FAQ</a></li>
                            </ul>
                          </nav>
                          <nav className="mobile-menu">
                            <ul itemScope="" itemType="http://www.schema.org/SiteNavigationElement">
                              <li itemProp="name"><a itemProp="url" href="#">Staking</a></li>
                              <li itemProp="name"><a itemProp="url" href="media/files/NRGY%20Research%20How%20It%20Works%20Paper.pdf" target="_blank">How it Works</a></li>
                              <li itemProp="name"><a itemProp="url" href="media/files/NRGY%20Minting%20Schedule.pdf" target="_blank">Minting Schedule</a></li>
                              <li itemProp="name"><a itemProp="url" href={"https://v2.info.uniswap.org/pair/0x51bf9908dac4a283d017edc071d954ec3a4b1376"} target="_blank">Uniswap</a></li>
                              <li itemProp="name"><a itemProp="url" href="/#/faqs" target="_blank" >FAQ</a></li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <Routes data={this.state} />
          </div>

          <script type="text/javascript" src="media/js/all-update-1-5-j.min.js"></script>
        </HashRouter>
      </div >
    );
  }

}

export default (App);
